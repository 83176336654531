import "chartjs-adapter-dayjs-4";

import { FormControlLabel, Switch, Box, Typography } from "@mui/material";
import { SeriesData } from "src/utils/types/outputTypes";
import { getSwitchColor } from "src/utils/functions/helpers";

type DataType = {
  portfolioData: { [key: string]: { [key: string]: SeriesData } };
  serviceData: { [key: string]: { [key: string]: SeriesData } };
  productsData: { [key: string]: { [key: string]: SeriesData } };
};

export const ChartLegend = ({
  chartData,
  currentDataset,
  chartDatasets,
  setChartDatasets,
  type,
}: {
  chartData: DataType;
  currentDataset: ("portfolioData" | "serviceData" | "productsData")[];
  chartDatasets: SeriesData[];
  setChartDatasets: (shownDatasets: any) => void;
  type?: "combi" | "future" | "history";
}) => {
  const arrayCopy = [...chartDatasets];
  const updateObject = ({
    dataSetId,
    hidden,
  }: {
    dataSetId: string;
    hidden: boolean;
  }) => {
    const index = chartDatasets.findIndex((obj) => obj.id === dataSetId);
    arrayCopy[index].hidden = !hidden;
    setChartDatasets(arrayCopy);
  };

  const changeAll = ({
    dataSetIds,
    hide,
  }: {
    dataSetIds: (string | undefined)[];
    hide: boolean;
  }) => {
    dataSetIds.forEach((id) => {
      const index = chartDatasets.findIndex((obj) => obj.id === id);
      arrayCopy[index].hidden = hide ? true : false;
    });
    setChartDatasets(arrayCopy);
  };

  return (
    <Box width={"100%"}>
      {currentDataset.flatMap((datasetName) =>
        Object.keys(chartData[datasetName]).map((subdatasetName, index) => {
          const datasetGroup =
            Object.keys(chartData[datasetName][subdatasetName]).map((data) => {
              return chartDatasets.find(
                (chartDataset) =>
                  chartDataset.id === `${subdatasetName}_${data}`
              );
            }) || [];

          return (
            <Box
              sx={{
                display: "grid",
                width: "100%",
                gridTemplateColumns:
                  type === "combi" ? "repeat(6, 1fr)" : "repeat(4, 1fr)",
                alignItems: "center",
              }}
              key={subdatasetName}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={datasetGroup.every((dataset) => !dataset?.hidden)}
                    onChange={(e, checked) => {
                      changeAll({
                        dataSetIds: datasetGroup.map((dataset) => dataset?.id),
                        hide: datasetGroup.every((dataset) => !dataset?.hidden)
                          ? true
                          : false,
                      });
                    }}
                    sx={getSwitchColor(
                      type === "future"
                        ? "#3f3f3f"
                        : datasetGroup[type === "combi" ? 3 : 0]?.borderColor
                    )}
                  />
                }
                label={
                  <Typography fontWeight={600}>
                    {datasetGroup[0]?.setLabel}
                  </Typography>
                }
              />

              {Object.keys(chartData[datasetName][subdatasetName]).map(
                (data) => {
                  const dataset = chartDatasets.find(
                    (dataset) => dataset.id === `${subdatasetName}_${data}`
                  );
                  if (dataset)
                    return (
                      <FormControlLabel
                        key={dataset.id}
                        control={
                          <Switch
                            checked={!dataset.hidden}
                            onChange={(e, checked) => {
                              updateObject({
                                dataSetId: dataset.id,
                                hidden: checked,
                              });
                            }}
                            sx={getSwitchColor(dataset?.borderColor)}
                          />
                        }
                        label={dataset.label}
                      />
                    );
                  return null;
                }
              )}
            </Box>
          );
        })
      )}
    </Box>
  );
};
