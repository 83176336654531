/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import dayjs from "dayjs";

// Utils
import { useInputContext } from "src/contexts/InputContext";
import { transformFutureData } from "src/utils/functions/chartDataConversion/transformFutureData";
import { transformCombiData } from "src/utils/functions/chartDataConversion/transformCombiData";
import { getInputsToPrompt } from "src/utils/functions/promptConversion/getInputsToPrompt";
import { transformHistoryData } from "src/utils/functions/chartDataConversion/transformHistoryData";
import { transformPerformanceData } from "src/utils/functions/chartDataConversion/transformPerformanceData";

// Queries
import {
  usePostHistory,
  usePostFuture,
  usePostHistoryPerformance,
} from "src/queries";

// Components
import MainPage from "./MainPage";
import { PageBar } from "src/components/atoms/PageBar";
import { CashFlowData } from "src/utils/types";
import { getDateFromArray } from "src/utils/functions";
import { chooseGranularity } from "src/utils/functions/promptConversion/promptConversionHelpers";
import { usePostHistoryPerformanceXIRR } from "src/queries/usePostHistoryPerformanceXIRR";

const DataWrapper = () => {
  const { inputItems, portfolioData } = useInputContext();
  const defaultPrompt = getInputsToPrompt({ portfolioData, data: inputItems });
  const [prompt, setPrompt] = useState(defaultPrompt);

  const [historyData, setHistoryData] = useState<any>({
    data: undefined,
    error: "",
    raw: undefined,
  });
  const [historyPerformanceData, setHistoryPerformanceData] = useState<any>({
    data: undefined,
    error: "",
    raw: undefined,
  });
  const [historyPerformanceDataXIRR, setHistoryPerformanceDataXIRR] =
    useState<any>({
      data: undefined,
      error: "",
      raw: undefined,
    });
  const [futureData, setFutureData] = useState<any>({
    data: undefined,
    error: "",
    raw: undefined,
  });
  const [combinationData, setCombinationData] = useState<any>({
    data: undefined,
    error: "",
    raw: undefined,
  });

  const currentDate = dayjs(new Date()).format("YYYY-MM-DD");
  const defaultFuture = dayjs(new Date()).add(1, "year").format("YYYY-MM-DD");
  const [timeRange, setTimeRange] = useState<{
    from: undefined | string;
    to: undefined | string;
    granularity: undefined | number;
  }>({
    from: currentDate,
    to: defaultFuture,
    granularity: undefined,
  });

  const postHistory = usePostHistory({
    onSuccess: (response: any) => {
      if (response.data) {
        const transformedData = transformHistoryData({
          data: response.data,
          input: inputItems,
        });
        setHistoryData({ ...transformedData, raw: response.data });
      }
    },
    onError: (response: any) => {
      if (response.response.data) {
        setHistoryData({
          data: undefined,
          error: `${response.response.data.title} details: ${response.response.data.detail}`,
        });
      }
    },
  });
  const postHistoryPerformance = usePostHistoryPerformance({
    onSuccess: (response: any) => {
      if (response.data) {
        const transformedData = transformPerformanceData({
          data: response.data,
          input: inputItems,
        });
        setHistoryPerformanceData({ ...transformedData, raw: response.data });
      }
    },
    onError: (response: any) => {
      if (response.response.data) {
        setHistoryPerformanceData({
          absolute: undefined,
          relative: undefined,
          error: `${response.response.data.title} details: ${response.response.data.detail}`,
        });
      }
    },
  });
  const postHistoryPerformanceXIRR = usePostHistoryPerformanceXIRR({
    onSuccess: (response: any) => {
      if (response.data) {
        const transformedData = transformPerformanceData({
          data: response.data,
          input: inputItems,
        });
        setHistoryPerformanceDataXIRR({
          ...transformedData,
          raw: response.data,
        });
      }
    },
    onError: (response: any) => {
      if (response.response.data) {
        setHistoryPerformanceDataXIRR({
          absolute: undefined,
          relative: undefined,
          error: `${response.response.data.title} details: ${response.response.data.detail}`,
        });
      }
    },
  });
  const postFuture = usePostFuture({
    onSuccess: (response: any) => {
      if (response.data) {
        const transformedData = transformFutureData({
          data: response.data,
          input: inputItems,
        });
        setFutureData({ ...transformedData, raw: response.data });
      }
    },
    onError: (response: any) => {
      if (response.response.data) {
        setFutureData({
          data: undefined,
          error: `${response.response.data.title} details: ${response.response.data.detail}`,
        });
      }
    },
  });
  const postCombi = usePostFuture({
    onSuccess: (response: any) => {
      if (response.data) {
        const transformedCombiData = transformCombiData({
          data: response.data,
          input: inputItems,
        });
        setCombinationData({ ...transformedCombiData, raw: response.data });
      }
    },
    onError: (response: any) => {
      if (response.response.data) {
        setCombinationData({
          data: undefined,
          error: `${response.response.data.title} details: ${response.response.data.detail}`,
        });
      }
    },
  });

  useEffect(() => {
    const newPrompt = getInputsToPrompt({ portfolioData, data: inputItems });

    const dateArray =
      newPrompt.portfolio.services.flatMap((service) =>
        service.products.flatMap((product) =>
          product.cashFlow.flatMap(
            (cashFlow: CashFlowData) => cashFlow?.from || cashFlow?.date
          )
        )
      ) || [];
    const futureDateArray =
      newPrompt.portfolio.services.flatMap((service) =>
        service.products.flatMap((product) =>
          product.cashFlow.flatMap(
            (cashFlow: CashFlowData) =>
              cashFlow?.to || cashFlow?.from || cashFlow?.date
          )
        )
      ) || [];

    const oldestDate = getDateFromArray({
      dateArr: [...dateArray, timeRange.from],
      direction: "oldest",
    });
    const newestDate = getDateFromArray({
      dateArr: [...futureDateArray, timeRange.to],
      direction: "newest",
    });

    const defaultGranularity = chooseGranularity({
      startDate: oldestDate,
      endDate: newestDate,
    });

    setTimeRange({
      granularity: defaultGranularity || 9,
      to: newestDate,
      from: oldestDate,
    });
    setPrompt(newPrompt);

    if (inputItems.length === 0) {
      setHistoryData({
        data: undefined,
        tableData: undefined,
        error: "",
      });
      setCombinationData({
        data: undefined,
        error: "",
      });
      setFutureData({ data: undefined, error: "" });
    }
  }, [inputItems, portfolioData]);

  const timeCondition =
    dayjs(timeRange.from).toDate().getTime() >
      dayjs("1986-01-31").toDate().getTime() &&
    dayjs(timeRange.to).toDate().getTime() >
      dayjs("1986-01-31").toDate().getTime();

  useEffect(() => {
    if (prompt?.portfolio?.services?.length > 0 && timeCondition) {
      postHistory.mutate({
        ...prompt,
        from: timeRange.from,
        to: currentDate,
        granularity: timeRange.granularity || 9,
      });

      postHistoryPerformance.mutate({
        ...prompt,
        from: timeRange.from,
        to: currentDate,
        granularity: timeRange.granularity || 9,
      });

      postHistoryPerformanceXIRR.mutate({
        ...prompt,
        from: timeRange.from,
        to: currentDate,
        granularity: timeRange.granularity || 9,
      });
    }
  }, [prompt, timeRange]);

  useEffect(() => {
    if (prompt?.portfolio?.services?.length > 0 && timeCondition) {
      postFuture.mutate({
        ...prompt,
        forecastFrom: currentDate,
        to: timeRange.to,
        granularity: timeRange.granularity || 9,
      });
    }
  }, [prompt, timeRange]);

  useEffect(() => {
    if (prompt?.portfolio?.services?.length > 0 && timeCondition) {
      postCombi.mutate({
        ...prompt,
        from: timeRange.from,
        to: timeRange.to,
        granularity: timeRange.granularity || 9,
      });
    }
  }, [prompt, timeRange]);

  return (
    <div className="App">
      <PageBar />

      <MainPage
        historyData={historyData}
        historyPerformanceData={historyPerformanceData}
        historyPerformanceDataXIRR={historyPerformanceDataXIRR}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        futureData={futureData}
        combinationData={combinationData}
        isFetching={{
          history: postHistory.isLoading,
          future: postFuture.isLoading,
          combination: postCombi.isLoading,
        }}
      />
    </div>
  );
};

export default DataWrapper;
