import { FormTypes } from "../../types/inputTypes";
import {
  isTypeBuildingSavings,
  isTypeDPS,
  isTypeCashAccount,
  isTypeSecurity,
  isTypeStrategy,
  isTypeTransformedFund,
} from "../helpers";
import { chooseGranularity } from "./promptConversionHelpers";
import { getSecurityPrompt } from "./getSecurityPrompt";
import { getStrategyPrompt } from "./getStrategyPrompt";
import { getDpsPrompt } from "./getDpsPrompt";
import { getBuildingSavingsPrompt } from "./getBuildingSavingsPrompt";
import { getCashAccountPrompt } from "./getCashAccountPrompt";

export const getInputsToPrompt = ({
  portfolioData,
  data,
}: {
  portfolioData: any;
  data: (FormTypes | undefined)[];
}) => {
  const result: {
    portfolio: {
      services: {
        id: number;
        products: any[];
      }[];
      currency: string;
    };
    granularity: number;
    from: string;
    to: string;
  } = {
    portfolio: {
      services: [],
      currency: portfolioData.currency,
    },
    granularity: 9,
    from: portfolioData.from,
    to: portfolioData.to,
  };

  const cleanedData: FormTypes[] = data.filter(
    (value): value is FormTypes => value !== undefined
  );

  cleanedData
    .filter((value) => value !== undefined)
    .forEach((product) => {
      let transformedProduct;
      if (isTypeSecurity(product))
        transformedProduct = getSecurityPrompt({ product: product });
      else if (isTypeStrategy(product))
        transformedProduct = getStrategyPrompt({ product: product });
      else if (isTypeDPS(product) || isTypeTransformedFund(product))
        transformedProduct = getDpsPrompt({ product: product });
      else if (isTypeBuildingSavings(product))
        transformedProduct = getBuildingSavingsPrompt({ product: product });
      else if (isTypeCashAccount(product))
        transformedProduct = getCashAccountPrompt({ product: product });

      const serviceIndex = result.portfolio.services.findIndex(
        (service) => service.id === product.serviceIn?.value
      );

      // Pokud služba neexistuje, vytvořte ji
      if (serviceIndex === -1) {
        result.portfolio.services.push({
          id: Number(product.serviceIn?.value),
          products: [transformedProduct],
        });
      } else {
        // Pokud služba existuje, přidejte produkt k existující službě
        result.portfolio.services[serviceIndex].products.push(
          transformedProduct
        );
      }
    });

  return result;
};
