/* eslint-disable no-nested-ternary */
import { useQuery } from "react-query";
import axios from "axios";

import { useAuth } from "src/contexts/AuthContext";
import { useConfig } from "src/contexts/ConfigContext";

export const useGetInvBusPartner = ({
  productType,
}: {
  productType: string;
}) => {
  const { authToken } = useAuth();
  const { config } = useConfig();

  const getOptionsFromFetch = (data: any) => {
    const filterData = () => {
      if (["portfolio-strategy", "security"].includes(productType)) {
        return data.rows.filter((obj: any) => obj.businessPartnerType.id === 1);
      } else if (productType === "cash-account") {
        return data.rows.filter((obj: any) => obj.businessPartnerType.id === 2);
      } else if (productType === "building-savings") {
        return data.rows.filter((obj: any) => obj.businessPartnerType.id === 3);
      } else if (["dps", "transformed-fund"].includes(productType)) {
        return data.rows.filter((obj: any) => obj.businessPartnerType.id === 4);
      } else return data.rows;
    };
    const transformedData = filterData().map((obj: any) => ({
      value: obj.id,
      label: obj.name.cs,
    }));
    transformedData.push({ value: "0", label: "Obecná služba" });
    return transformedData;
  };

  const fetch = async () => {
    const res = await axios.get(
      `${config.catalogUrl}${config.version}/Enum/BusinessPartner`,
      {
        params: {
          pageSize: 100,
        },
        headers: {
          accept: "application/json",
          contentType: "application/json",
          correlationid: "382d3541-1c07-47d9-8426-d04152e82261",
          ofsauthorization: `Bearer ${authToken}`,
          userAgent: "vscode-restclient",
        },
      }
    );
    const transformedData = getOptionsFromFetch(res.data);
    return transformedData;
  };

  return useQuery(`getInvBusPartner-${productType}`, fetch, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
    enabled: !!authToken && !!config.catalogUrl,
  });
};
