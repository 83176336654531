/* eslint-disable no-nested-ternary */
import { useQuery } from "react-query";
import axios from "axios";

import QueryString from "qs";
import { useAuth } from "src/contexts/AuthContext";
import { useConfig } from "src/contexts/ConfigContext";

export const useGetTransformedFunds = ({
  businessPartnerIn,
  search,
  dataType,
}: {
  businessPartnerIn?: string | number;
  search?: string;
  dataType: "proxy" | "specific" | "";
}) => {
  const { authToken } = useAuth();
  const { config } = useConfig();

  const getOptionsFromFetch = (data: any) => {
    return data.rows.map((obj: any) => ({
      value: obj.identification.id,
      label: `${obj.info.name.cs}${
        dataType === "specific"
          ? `; #${obj.identification.id}; ${obj.identification.businessPartner?.name.cs}`
          : ""
      }`,
      data: {
        invBusPartnerIn:
          dataType === "specific" ? obj.identification.businessPartner?.id : "",
        currency: obj.paymentInstructions.items[0]?.currency?.code || "CZK",
      },
    }));
  };
  const fetch = async () => {
    const res = await axios.get(
      `${config.catalogUrl}${config.version}/TransformedFunds`,
      {
        params: {
          hasVariantIs: true,
          pageSize: 50,
          businessPartnerIn:
            dataType === "proxy" ? null : businessPartnerIn || null,
          search: search || null,
          identificationsIn: dataType === "proxy" ? "PORTLAB:default" : null,
        },
        paramsSerializer: {
          serialize: (params) => {
            return QueryString.stringify(params, { skipNulls: true });
          },
        },

        headers: {
          accept: "application/json",
          contentType: "application/json",
          correlationid: "382d3541-1c07-47d9-8426-d04152e82261",
          ofsauthorization: `Bearer ${authToken}`,
          userAgent: "vscode-restclient",
        },
      }
    );
    const transformedData = getOptionsFromFetch(res.data);
    return transformedData;
  };

  return useQuery(
    `getTransformedFund-${businessPartnerIn}-${search}-${dataType}`,
    fetch,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
      enabled: !!authToken && !!config.catalogUrl && !!dataType,
    }
  );
};
