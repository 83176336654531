/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Stack } from "@mui/material";

// Utils, queries
import { useInputContext } from "src/contexts/InputContext";
import {
  serviceDefinitionFields,
  withdrawalsFields,
  cashFlowFields,
} from "./fieldLists";
import {
  useGetCurrency,
  useGetInvBusPartner,
  useGetServices,
} from "src/queries";
import { findOptionForValue } from "src/utils/functions/helpers";
import { advisoryProductGroups } from "src/utils/enums/advisoryProductGroups";
import { DPSFormProps } from "src/utils/types/inputTypes";
import { transformedFundProxy, modelationTypeOptions } from "src/utils/enums";

// Components
import BasicSection from "../components/BasicSection";
import RepeatableSection from "../components/RepeatableSection";
import {
  cashFlowDefault,
  defaultValues,
  withdrawalsDefault,
} from "./defaultValues";
import { FormButtons, FormSectionWrapper } from "../components";
import PortfolioCurrencyField from "../components/PortfolioCurrencyField";
import { frequencyEnum } from "src/utils/enums/optionsEnums";
import { useGetTransformedFunds } from "src/queries/products/useGetTransformedFunds";

export const TransformedFundForm = ({ setOpen }: any) => {
  const { formData, setFormData, addInputItem, updateInputItem } =
    useInputContext();

  const securityForm = useForm<DPSFormProps>({
    defaultValues: formData || defaultValues,
  });
  const { register, handleSubmit, setValue, getValues, watch, reset } =
    securityForm;

  const handleSave = () => {
    const timeStamp = Date.now();
    const title =
      getValues("product") && getValues("product.label")
        ? getValues("product.label")
        : String(timeStamp);
    if (getValues("id")) {
      updateInputItem(Number(getValues("id")), {
        ...getValues(),
        title: title,
      });
    } else
      addInputItem({
        ...getValues(),
        id: timeStamp,
        title: title,
      });

    setFormData(undefined);
    setOpen(false);
    reset();
  };

  const onSubmit = (data: any) => {
    handleSave();
  };

  const { data: currencyOptions } = useGetCurrency();
  const { data: invBusPartnerOptions } = useGetInvBusPartner({
    productType: "transformed-fund",
  });
  const { data: productOptions } = useGetTransformedFunds({
    businessPartnerIn: watch("invBusPartnerIn")?.value,
    search: watch("product")?.label?.split(";")[0],
    dataType: watch("modelationType")?.value,
  });
  const frequencyOptions = frequencyEnum;

  // Dependant fields
  useEffect(() => {
    const productData = watch("product")?.data;
    const productBusPartner = productData?.invBusPartnerIn;

    if (productBusPartner) {
      const invBusPartnerObject = findOptionForValue({
        options: invBusPartnerOptions,
        value: productBusPartner,
      });
      if (invBusPartnerObject) {
        setValue("invBusPartnerIn", invBusPartnerObject);
      }
    }
  }, [watch("product")]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="32px">
        <BasicSection
          title={"DEFINICE INVESTIČNÍ SLUŽBY"}
          form={securityForm}
          options={{
            productTypeOptions: advisoryProductGroups,
            productOptions,
            invBusPartnerOptions,
            modelationTypeOptions,
            currencyOptions,
          }}
          fields={serviceDefinitionFields}
        />

        <FormSectionWrapper title={"Referenční měna portfolia"}>
          <Box sx={{ width: "100%", gridColumn: 2, gap: "20px" }}>
            <PortfolioCurrencyField />
          </Box>
        </FormSectionWrapper>

        <RepeatableSection
          name={"clientCashFlow"}
          title={"Příspěvek - klient"}
          form={securityForm}
          options={{
            currencyOptions,
            frequencyOptions,
          }}
          fields={cashFlowFields}
          defaultValues={cashFlowDefault}
        />

        <RepeatableSection
          name={"employerCashFlow"}
          title={"příspěvek - zaměstnavatel"}
          form={securityForm}
          options={{
            currencyOptions,
            frequencyOptions,
          }}
          fields={cashFlowFields}
          defaultValues={cashFlowDefault}
        />

        <RepeatableSection
          name={"withdrawals"}
          title={"Výběry / prodej"}
          form={securityForm}
          options={{
            frequencyOptions,
          }}
          fields={withdrawalsFields}
          defaultValues={withdrawalsDefault}
        />

        <FormButtons setOpen={setOpen} />
      </Stack>
    </form>
  );
};
