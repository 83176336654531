import * as React from "react";
import { FormFieldProps, OptionType } from "../../../utils/types/inputTypes";
import { FormSectionWrapper } from "./FormSectionWrapper";
import SectionContent from "./SectionContent";
import { UseFormReturn } from "react-hook-form";

interface SectionProps {
  form: UseFormReturn<any, any, undefined>;
  title: string;
  options: {
    [key: string]: OptionType[] | undefined;
  };
  fields: FormFieldProps[];
}

const BasicSection = ({ form, title, options, fields }: SectionProps) => {
  const { setValue } = form;
  return (
    <FormSectionWrapper title={title} key={`${title}-section`}>
      <SectionContent
        form={form}
        options={options || {}}
        fields={fields}
        key={`${title}-section-content`}
        onChangeField={(name, newValue) => setValue(name, newValue)}
      />
    </FormSectionWrapper>
  );
};

export default BasicSection;
