import * as React from "react";
import { ReactNode } from "react";
import { Dialog, AppBar, IconButton, Toolbar, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const FormDialogWrapper = ({
  open,
  setOpen,
  children,
}: {
  open: any;
  setOpen: any;
  children: ReactNode;
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      sx={{
        ".MuiDialog-paper": {
          position: "absolute",
          top: 0,
          right: 0,
          maxHeight: "100vh",
          margin: 0,
        },
      }}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <IconButton
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        sx={{ position: "absolute", top: "14px", right: "14px" }}
      >
        <CloseIcon />
      </IconButton>

      {children}
    </Dialog>
  );
};
