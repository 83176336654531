import { currencyFormatter } from "src/utils/functions/helpers";
import { Product, ValuationData } from "src/utils/types/outputTypes";

export const instrumentsLabels = {
  name: "Název fondu",
  totalDeposits: "Vklady celkem",
  investments: "Čisté vklady (nákupy) celkem",
  withdrawals: "Výběry celkem",
  value: "Hodnota na konci modelace",
};

export const getInstrumentsData = (
  portfolioData: ValuationData,
  data: Product
) => {
  if (data?.summary) {
    return {
      name: data.productId,
      totalDeposits: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(
        data.summary.clientDeposits +
          data.summary.employerContributions +
          data.summary.governmentContributions +
          data.summary.interests
      ),
      investments: currencyFormatter(portfolioData?.portfolio?.currency).format(
        data.summary.investments
      ),
      withdrawals: currencyFormatter(portfolioData?.portfolio?.currency).format(
        data.summary.withdrawals
      ),
      value: currencyFormatter(portfolioData?.portfolio?.currency).format(
        data.summary.value
      ),
    };
  }
};
