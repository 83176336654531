import { TooltipItem } from "chart.js";
import {
  currencyFormatter,
  percentFormatter,
} from "src/utils/functions/helpers";

export const formatTooltip = ({
  portfolioCurrency,
  context,
}: {
  portfolioCurrency: string;
  context: TooltipItem<"line">;
}) => {
  const type = context.dataset.yAxisID;
  let label = context.dataset.label || "";

  if (label) {
    label += ": ";
  }
  if (context.parsed.y !== null) {
    if (type === "relative") {
      label += percentFormatter().format(context.parsed.y);
    } else if (type === "navs") {
      label += context.parsed.y;
    } else {
      label += currencyFormatter(portfolioCurrency).format(context.parsed.y);
    }
  }

  return label;
};
