/* eslint-disable no-nested-ternary */
import { useQuery } from "react-query";
import axios from "axios";
import QueryString from "qs";
import { useAuth } from "src/contexts/AuthContext";
import { useConfig } from "src/contexts/ConfigContext";

export const useGetSecuritiesInfo = ({
  isinIn,
  serviceIn,
}: {
  isinIn?: string;
  serviceIn?: string;
}) => {
  const { authToken } = useAuth();
  const { config } = useConfig();

  const getOptionsFromFetch = (data: any) => {
    return data.rows.flatMap((obj: any) => {
      return {
        value: isinIn,
        name: obj?.securityIdentification?.name.cs || "- -",
      };
    });
  };

  const fetch = async () => {
    const res = await axios.get(
      `${config.catalogUrl}${config.version}/Securities`,
      {
        params: {
          isinIn: isinIn || null,
          serviceIn: serviceIn || null,
        },
        paramsSerializer: {
          serialize: (params) => {
            return QueryString.stringify(params, { skipNulls: true });
          },
        },
        headers: {
          correlationid: "382d3541-1c07-47d9-8426-d04152e82261",
          ofsauthorization: `Bearer ${authToken}`,
        },
      }
    );
    const transformedData = getOptionsFromFetch(res.data);
    return transformedData;
  };

  return useQuery(`getSecuritiesInfo-${isinIn}-${serviceIn}`, fetch, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
    enabled: !!authToken && !!config.catalogUrl,
  });
};
