import * as React from "react";
import { useState, useEffect } from "react";
import "chartjs-adapter-dayjs-4";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Stack, Box, Typography } from "@mui/material";
import { FutureData, SeriesData } from "src/utils/types/outputTypes";
import { chartOptions } from "../utils/chartOptions";
import { PortfolioCurrencyPicker } from "src/components/buttons/PortfolioCurrencyPicker";
import dayjs from "dayjs";
import { ChartLegend } from "../ChartLegend";
import { getChartOptions } from "../utils/getChartOptions";
import { useInputContext } from "src/contexts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  PointElement,
  LineElement,
  Legend,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const CombiChart = ({
  data,
  tableData,
  loading,
  open,
  currentDataset,
}: {
  data: {
    portfolioData: { [key: string]: { [key: string]: SeriesData } };
    serviceData: { [key: string]: { [key: string]: SeriesData } };
    productsData: { [key: string]: { [key: string]: SeriesData } };
  };
  tableData: FutureData;
  loading: boolean;
  open: boolean;
  currentDataset: ("portfolioData" | "serviceData" | "productsData")[];
}) => {
  const { portfolioData } = useInputContext();
  const getDataset = (
    names: ("portfolioData" | "serviceData" | "productsData")[]
  ) => {
    return names.flatMap((datasetName) =>
      Object.keys(data[datasetName]).flatMap((subdatasetName) => {
        return [
          data[datasetName][subdatasetName].negative,
          data[datasetName][subdatasetName].neutral,
          data[datasetName][subdatasetName].positive,
          data[datasetName][subdatasetName].cashFlow,
          data[datasetName][subdatasetName].valuation,
        ];
      })
    );
  };

  const [chartDatasets, setChartDatasets] = useState<SeriesData[]>(
    getDataset(currentDataset)
  );

  // Efekt pro aktualizaci shownDatasets na základě currentDataset
  useEffect(() => {
    const newDatasets = getDataset(currentDataset);
    setChartDatasets(newDatasets);
  }, [currentDataset, data]);

  const options = React.useMemo(
    () =>
      getChartOptions({
        portfolioCurrency: portfolioData.currency,
        datasetLength:
          chartDatasets[0].data.length + chartDatasets[3].data.length,
      }),
    [portfolioData, currentDataset, chartDatasets]
  );

  return (
    <Stack gap="24px">
      <Box boxShadow={2} borderRadius={2} padding={2}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 2fr",
            marginBottom: "12px",
          }}
        >
          <Typography
            fontSize={"1rem"}
            textTransform={"uppercase"}
            fontWeight={500}
            sx={{ justifySelf: "start" }}
          >
            {`Předpokládaná hodnota portfolia ${dayjs(tableData.to).format(
              "DD. MM. YYYY"
            )}`}
          </Typography>
          <PortfolioCurrencyPicker
            loading={loading}
            valuation={
              tableData?.forecast.neutral.portfolio?.summary?.value || 0
            }
          />
        </Box>

        <Box sx={{ height: "50vh", marginBottom: "24px" }}>
          <Line
            data={{
              datasets: chartDatasets,
            }}
            options={options}
            redraw={true}
            key={`${open}`}
          />
        </Box>
      </Box>
      <ChartLegend
        currentDataset={currentDataset}
        setChartDatasets={setChartDatasets}
        chartDatasets={chartDatasets}
        chartData={data}
        type="combi"
      />
    </Stack>
  );
};
