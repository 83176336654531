import * as React from "react";
import { useState, useEffect } from "react";
import "chartjs-adapter-dayjs-4";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { Stack, FormControlLabel, Switch, Box } from "@mui/material";
import { SeriesData, ValuationData } from "src/utils/types/outputTypes";
import { PortfolioCurrencyPicker } from "src/components/buttons/PortfolioCurrencyPicker";
import { HistoryDataPicker } from "src/components/buttons/HistoryDataPicker";

import { useInputContext } from "src/contexts/InputContext";
import { getChartOptions } from "../utils/getChartOptions";
import { ChartLegend } from "../ChartLegend";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  PointElement,
  LineElement,
  Legend,
  Title,
  Tooltip,
  Legend,
  Filler
);

type DataType = {
  portfolioData: { [key: string]: { [key: string]: SeriesData } };
  serviceData: { [key: string]: { [key: string]: SeriesData } };
  productsData: { [key: string]: { [key: string]: SeriesData } };
};

export const HistoryChart = ({
  chartData,
  performanceData,
  tableData,
  loading,
  open,
  currentDataset,
}: {
  tableData: ValuationData;
  performanceData: { absolute: DataType; relative: DataType };
  chartData: DataType;
  loading: boolean;
  open: boolean;
  currentDataset: ("portfolioData" | "serviceData" | "productsData")[];
}) => {
  const { portfolioData } = useInputContext();
  const [currentDataType, setCurrentDataType] = useState<
    "valuation" | "absolute" | "relative"
  >("valuation");

  const getDataset = (
    names: ("portfolioData" | "serviceData" | "productsData")[]
  ) => {
    if (currentDataType === "absolute")
      return names.flatMap((datasetName) =>
        Object.keys(chartData[datasetName]).flatMap((subdatasetName) => [
          performanceData?.absolute[datasetName][subdatasetName].absolute,
          chartData[datasetName][subdatasetName].cashFlow,
        ])
      );
    else if (currentDataType === "relative")
      return names.flatMap((datasetName) =>
        Object.keys(chartData[datasetName]).flatMap((subdatasetName) => [
          performanceData?.relative[datasetName][subdatasetName].relative,
          chartData[datasetName][subdatasetName].cashFlow,
        ])
      );
    else
      return names.flatMap((datasetName) =>
        Object.keys(chartData[datasetName]).flatMap((subdatasetName) =>
          [
            chartData[datasetName][subdatasetName].valuation,
            chartData[datasetName][subdatasetName].nav || null,
            chartData[datasetName][subdatasetName].cashFlow,
          ].filter((x) => x)
        )
      );
  };

  const [chartDatasets, setChartDatasets] = useState<SeriesData[]>(
    getDataset(currentDataset)
  );

  // Efekt pro aktualizaci shownDatasets na základě currentDataset
  useEffect(() => {
    const newDatasets = getDataset(currentDataset);
    setChartDatasets(newDatasets);
  }, [currentDataset, chartData, currentDataType]);

  const options = React.useMemo(
    () =>
      getChartOptions({
        portfolioCurrency: portfolioData.currency,
        currentDataType,
        showNavs: currentDataset.includes("productsData"),
        datasetLength: chartDatasets[0].data.length,
      }),
    [portfolioData, currentDataType, currentDataset, chartDatasets]
  );

  return (
    <Stack gap="24px">
      <Box boxShadow={2} borderRadius={2} padding={2}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 2fr",
            marginBottom: "12px",
          }}
        >
          <HistoryDataPicker
            pickedType={currentDataType}
            setPickedType={setCurrentDataType}
            disabled={!performanceData.absolute || !performanceData.relative}
          />
          <PortfolioCurrencyPicker
            loading={loading}
            valuation={tableData.portfolio.summary?.value || 0}
          />
        </Box>

        {/* Chart */}
        <Box sx={{ height: "50vh", marginBottom: "24px" }}>
          <Line
            data={{
              datasets: chartDatasets,
            }}
            options={options}
            redraw={true}
            key={`${currentDataType}-${currentDataset}-${open}`}
          />
        </Box>
      </Box>

      <ChartLegend
        currentDataset={currentDataset}
        setChartDatasets={setChartDatasets}
        chartDatasets={chartDatasets}
        chartData={
          currentDataType === "valuation"
            ? chartData
            : currentDataType === "relative"
            ? performanceData.relative
            : performanceData.absolute
        }
      />
    </Stack>
  );
};
