export const styles = {
  title: { fontSize: "1rem", marginBottom: 1, fontWeight: 500 },
  table: {
    minWidth: 650,
    padding: 1,
    boxShadow: 2,
    borderRadius: 2,
  },
  tableHeaderRow: {
    backgroundColor: "#f5f5f5",
  },
  tableHeader: {
    color: "#6B7280",
    fontWeight: 400,
  },
};
