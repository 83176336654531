import { FormFieldProps } from "src/utils/types/inputTypes";

export const withdrawalsFields: FormFieldProps[] = [
  {
    type: "textField",
    label: "Výše výběru (prodej investice)",
    name: "amount",
    fieldType: "number",
  },
  { type: "datePick", label: "Od (datum)", name: "from" },
  { type: "datePick", label: "Do (datum)", name: "to" },
  {
    type: "autoComplete",
    label: "Frekvence výběru",
    name: "frequency",
    optionsKey: "frequencyOptions",
  },
];
