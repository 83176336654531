/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Stack, Box } from "@mui/material";

import BasicSection from "../components/BasicSection";
import RepeatableSection from "../components/RepeatableSection";

import {
  serviceDefinitionFields,
  ISINFields,
  feesFields,
  withdrawalsFields,
  cashFlowFields,
} from "./fieldLists";

import {
  useGetCurrency,
  useGetFeePaymentMethod,
  useGetInvBusPartner,
  useGetSecurities,
  useGetServices,
} from "src/queries";

import { modelationTypeOptions } from "src/utils/enums";

import {
  cashFlowDefault,
  defaultValues,
  withdrawalsDefault,
} from "./defaultValues";
import { useInputContext } from "src/contexts/InputContext";
import { findOptionForValue } from "src/utils/functions/helpers";
import { SecurityFormProps } from "src/utils/types/inputTypes";
import PortfolioCurrencyField from "../components/PortfolioCurrencyField";
import { FormButtons, FormSectionWrapper } from "../components";
import { advisoryProductGroups } from "src/utils/enums/advisoryProductGroups";
import { frequencyEnum } from "src/utils/enums/optionsEnums";

export const SecurityForm = ({ setOpen }: any) => {
  const { formData, setFormData, updateInputItem, addInputItem } =
    useInputContext();

  const securityForm = useForm<SecurityFormProps>({
    defaultValues: formData || defaultValues,
  });

  const { handleSubmit, setValue, getValues, watch, reset } = securityForm;

  const handleSave = () => {
    const timeStamp = Date.now();
    const title =
      getValues("product") && getValues("product.label")
        ? `${getValues("product.label").split(";")[0]}`
        : String(timeStamp);
    if (getValues("id")) {
      updateInputItem(Number(getValues("id")), {
        ...getValues(),
        title: title,
      });
    } else
      addInputItem({
        ...getValues(),
        id: timeStamp,
        title: title,
      });

    setFormData(undefined);
    setOpen(false);
    reset();
  };

  // Service definition
  const { data: invBusPartnerOptions } = useGetInvBusPartner({
    productType: "security",
  });

  const { data: servicesOptions } = useGetServices({
    invBusPartnerIn: watch("invBusPartnerIn")?.value,
  });

  const { data: currencyOptions } = useGetCurrency();
  const { data: securitiesOptions, refetch: refetchSecurities } =
    useGetSecurities({
      serviceIn: watch("serviceIn")?.value,
      invBusPartnerIn: watch("invBusPartnerIn")?.value,
      search: watch("product")?.label?.split(";")[0],
      dataType: watch("modelationType")?.value,
    });

  useEffect(() => {
    refetchSecurities();
  }, [watch("product")?.label]);

  const typeOfFeeOptions = [
    { value: "purchase", label: "Vstupní" },
    { value: "redemption", label: "Výstupní" },
  ];
  const { data: feePaymentMethodOptions } = useGetFeePaymentMethod();
  const frequencyOptions = frequencyEnum;

  // Dependant fields
  useEffect(() => {
    const productData = watch("product")?.data;
    const productCurrency = productData?.currency;
    const productService = productData?.serviceIn;
    const productBusPartner = productData?.invBusPartnerIn;

    if (productCurrency) {
      const currencyObject = findOptionForValue({
        options: currencyOptions,
        value: productCurrency,
      });
      if (currencyObject) {
        setValue("currency", currencyObject);
      }
    }
    if (!getValues("serviceIn")?.value && productService) {
      const productServiceObject = findOptionForValue({
        options: servicesOptions,
        value: productService,
      });
      if (productServiceObject) {
        setValue("serviceIn", productServiceObject);
      }
    }
    if (!getValues("invBusPartnerIn")?.value && productBusPartner) {
      const productBusPartnerObject = findOptionForValue({
        options: invBusPartnerOptions,
        value: productBusPartner,
      });
      if (productBusPartnerObject) {
        setValue("invBusPartnerIn", productBusPartnerObject);
      }
    }
  }, [watch("product")]);

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Stack gap="32px">
        <BasicSection
          title={"DEFINICE INVESTIČNÍ SLUŽBY"}
          form={securityForm}
          options={{
            invBusPartnerOptions,
            servicesAndContractsOptions: servicesOptions,
            productTypeOptions: advisoryProductGroups,
          }}
          fields={serviceDefinitionFields}
        />

        <FormSectionWrapper title={"Referenční měna portfolia"}>
          <Box sx={{ width: "100%", gridColumn: 2, gap: "20px" }}>
            <PortfolioCurrencyField />
          </Box>
        </FormSectionWrapper>

        <BasicSection
          title={"Výběr fondu / ISINu"}
          form={securityForm}
          options={{
            modelationTypeOptions,
            currencyOptions,
            securitiesOptions,
          }}
          fields={ISINFields}
        />

        <BasicSection
          title={"Poplatky & způsob úhrady"}
          form={securityForm}
          options={{
            typeOfFeeOptions,
            feePaymentMethodOptions,
          }}
          fields={
            watch("typeOfFee")?.value === "redemption"
              ? feesFields.filter((field) => field.name !== "feePaymentMethod")
              : feesFields
          }
        />

        <RepeatableSection
          name={"cashFlow"}
          title={"Vklady / nákupy"}
          form={securityForm}
          options={{
            currencyOptions,
            frequencyOptions,
          }}
          fields={cashFlowFields}
          defaultValues={cashFlowDefault}
        />

        <RepeatableSection
          name={"withdrawals"}
          title={"Výběry / prodej"}
          form={securityForm}
          options={{
            frequencyOptions,
          }}
          fields={withdrawalsFields}
          defaultValues={withdrawalsDefault}
        />

        <FormButtons setOpen={setOpen} />
      </Stack>
    </form>
  );
};
