import { FormFieldProps } from "src/utils/types/inputTypes";

export const serviceDefinitionFields: FormFieldProps[] = [
  {
    type: "autoComplete",
    label: "Typ produktu",
    name: "type",
    disabled: true,
    optionsKey: "productTypeOptions",
  },
  {
    type: "autoComplete",
    label: "Název obchodního partnera",
    name: "invBusPartnerIn",
    optionsKey: "invBusPartnerOptions",
    placeHolder: "Zadejte",
  },
  {
    type: "autoComplete",
    label: "Modelace obecného vs konkrétního instrumentu",
    name: "modelationType",
    optionsKey: "modelationTypeOptions",
  },
  {
    type: "autoComplete",
    label: "Název produktu",
    name: "product",
    optionsKey: "dpsOptions",
    placeHolder: "Zadejte",
    required: true,
  },
  {
    type: "textField",
    label: "Měna fondu",
    name: "product.data.currency",
    placeHolder: "Doplněna automaticky",
    optionsKey: "currencyOptions",
    disabled: true,
  },
];
