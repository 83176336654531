import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { getServicesData, servicesLabels } from "./utils";
import { ValuationData } from "src/utils/types/outputTypes";
import { useGetServicesInfo } from "src/queries";
import { useInputContext } from "src/contexts";
import { styles } from "../styles";

export const ServiceNameCellContent = ({
  serviceIn,
}: {
  serviceIn: string;
}) => {
  const { data } = useGetServicesInfo({ serviceIn });
  if (serviceIn === "0") return <span>{"- -"}</span>;
  return <span>{data ? data[0]?.name : "- -"}</span>;
};

export const ServicesTable = ({
  data,
  filteredType,
}: {
  data: ValuationData;
  filteredType: string | number | undefined;
}) => {
  const { sortedItems } = useInputContext();
  const serviceIDs = filteredType
    ? sortedItems[filteredType].map((item) => item.serviceID)
    : [];

  return (
    <Box>
      <Typography sx={styles.title}>Služby/smlouvy v portfoliu</Typography>
      <Table sx={styles.table} aria-label="simple table">
        <TableHead sx={styles.tableHeaderRow}>
          <TableRow>
            {Object.keys(servicesLabels).map((key) => (
              <TableCell
                key={key}
                component="th"
                scope="row"
                sx={styles.tableHeader}
              >
                {servicesLabels[key as keyof typeof servicesLabels]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.portfolio.byServices.map((service) => {
            return filteredType &&
              !serviceIDs.includes(service.serviceId) ? null : (
              <TableRow key={service.serviceId}>
                {Object.keys(servicesLabels).map((key) => {
                  const instrumentData = getServicesData(data, service) || {};
                  return (
                    <TableCell
                      key={`${service.serviceId}-${key}`}
                      component="th"
                      scope="row"
                    >
                      {key === "name" ? (
                        <ServiceNameCellContent
                          serviceIn={
                            instrumentData[key as keyof typeof instrumentData]
                          }
                        />
                      ) : (
                        instrumentData[key as keyof typeof instrumentData]
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
