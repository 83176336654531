import { currencyFormatter } from "src/utils/functions/helpers";
import { PortfolioData } from "src/utils/types/inputTypes";
import {
  PortfolioDataType,
  Product,
  ValuationData,
} from "src/utils/types/outputTypes";

export const instrumentsLabels = {
  name: "Název fondu",
  regularPurchaseFees: "Vstupní poplatky, pravidelné",
  standardPurchaseFees: "Vstupní poplatky, z prvních plateb",
  expressPurchaseFees: "Vstupní poplatky, expresní",
  oneOffPurchaseFees: "Vstupní poplatky z jednorázových plateb",
  redemptionFees: "Výstupní poplatky",
};

export const getInstrumentsData = (
  portfolioData: ValuationData,
  data: Product
) => {
  if (data?.summary) {
    return {
      name: data.productId,
      regularPurchaseFees: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.regularPurchaseFees),
      standardPurchaseFees: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.standardPurchaseFees),
      expressPurchaseFees: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.expressPurchaseFees),
      oneOffPurchaseFees: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.oneOffPurchaseFees),
      redemptionFees: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.redemptionFees),
    };
  }
};

export const getPortfolioData = (
  portfolioData: ValuationData,
  data: PortfolioDataType
) => {
  if (data?.summary) {
    return {
      name: "Portfolio",
      regularPurchaseFees: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.regularPurchaseFees),
      standardPurchaseFees: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.standardPurchaseFees),
      expressPurchaseFees: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.expressPurchaseFees),
      oneOffPurchaseFees: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.oneOffPurchaseFees),
      redemptionFees: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.redemptionFees),
    };
  }
};
