/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Stack } from "@mui/material";

// Utils, queries
import { useInputContext } from "src/contexts/InputContext";
import { serviceDefinitionFields, cashFlowFields } from "./fieldLists";
import {
  useGetCurrency,
  useGetInvBusPartner,
  useGetServices,
} from "src/queries";
import { findOptionForValue } from "src/utils/functions/helpers";
import { advisoryProductGroups } from "src/utils/enums/advisoryProductGroups";
import { CashAccountFormProps } from "src/utils/types/inputTypes";
import { cashAccountProxy, modelationTypeOptions } from "src/utils/enums";

// Components
import BasicSection from "../components/BasicSection";
import RepeatableSection from "../components/RepeatableSection";
import { cashFlowDefault, defaultValues } from "./defaultValues";
import { FormButtons, FormSectionWrapper } from "../components";
import PortfolioCurrencyField from "../components/PortfolioCurrencyField";
import { frequencyEnum } from "src/utils/enums/optionsEnums";
import { interestConditionsFields } from "./interestConditionsFields";
import { useGetCashAccounts } from "src/queries/products/useGetCashAccounts";

export const CashAccountForm = ({ setOpen }: any) => {
  const { formData, setFormData, addInputItem, updateInputItem } =
    useInputContext();

  const productForm = useForm<CashAccountFormProps>({
    defaultValues: formData || defaultValues,
  });
  const { handleSubmit, setValue, getValues, watch, reset } = productForm;

  const handleSave = () => {
    const timeStamp = Date.now();
    const title =
      getValues("product") && getValues("product.label")
        ? getValues("product.label")
        : String(timeStamp);
    if (getValues("id")) {
      updateInputItem(Number(getValues("id")), {
        ...getValues(),
        title: title,
      });
    } else
      addInputItem({
        ...getValues(),
        id: timeStamp,
        title: title,
      });

    setFormData(undefined);
    setOpen(false);
    reset();
  };

  const onSubmit = (data: any) => {
    handleSave();
  };

  const { data: currencyOptions } = useGetCurrency();
  const { data: invBusPartnerOptions } = useGetInvBusPartner({
    productType: "cash-account",
  });
  const { data: productOptions } = useGetCashAccounts({
    businessPartnerIn: watch("invBusPartnerIn")?.value,
    search: watch("product")?.label?.split(";")[0],
    dataType: watch("modelationType")?.value,
  });
  const frequencyOptions = frequencyEnum;

  // Dependant fields
  useEffect(() => {
    const productData = watch("product")?.data;
    const productBusPartner = productData?.invBusPartnerIn;

    if (productBusPartner) {
      const invBusPartnerObject = findOptionForValue({
        options: invBusPartnerOptions,
        value: productBusPartner,
      });
      if (invBusPartnerObject) {
        setValue("invBusPartnerIn", invBusPartnerObject);
      }
    }
  }, [watch("product")]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="32px">
        <BasicSection
          title={"DEFINICE INVESTIČNÍ SLUŽBY"}
          form={productForm}
          options={{
            productTypeOptions: advisoryProductGroups,
            productOptions,
            invBusPartnerOptions,
            modelationTypeOptions,
          }}
          fields={serviceDefinitionFields}
        />

        <FormSectionWrapper title={"Referenční měna portfolia"}>
          <Box sx={{ width: "100%", gridColumn: 2, gap: "20px" }}>
            <PortfolioCurrencyField />
          </Box>
        </FormSectionWrapper>

        <RepeatableSection
          name={"cashFlow"}
          title={"Vklady"}
          form={productForm}
          options={{
            currencyOptions,
            frequencyOptions,
          }}
          fields={cashFlowFields}
          defaultValues={cashFlowDefault}
        />

        <BasicSection
          title={"Úrokové sazby"}
          form={productForm}
          options={{}}
          fields={interestConditionsFields}
        />

        <FormButtons setOpen={setOpen} />
      </Stack>
    </form>
  );
};
