import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { ChevronIcon } from "../../icons";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Box, Stack } from "@mui/material";

export const TimeRangePicker = ({
  timeRange,
  setTimeRange,
  modelationType,
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const currentDate = dayjs(new Date()).format("YYYY-MM-DD");

  return (
    <>
      <Button
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
        startIcon={<ChevronIcon up={!open} />}
      >
        {`Od ${dayjs(
          modelationType === "future" ? currentDate : timeRange.from
        ).format("DD. MM. YYYY")}`}
        {` do ${dayjs(
          modelationType === "history" ? currentDate : timeRange.to
        ).format("DD. MM. YYYY")}`}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: "24px" }}>
          <Stack gap={"12px"}>
            <DatePicker
              label={"Od"}
              value={
                modelationType === "future"
                  ? dayjs(currentDate)
                  : dayjs(timeRange.from)
              }
              onChange={(newValue) =>
                setTimeRange({
                  ...timeRange,
                  from: dayjs(newValue).format("YYYY-MM-DD"),
                })
              }
              slotProps={{
                textField: {
                  variant: "standard",
                  InputLabelProps: { shrink: true },
                },
              }}
              minDate={dayjs("1986-01-31")}
              disabled={modelationType === "future"}
            />
            <DatePicker
              label={"Do"}
              value={
                modelationType === "history"
                  ? dayjs(currentDate)
                  : dayjs(timeRange.to)
              }
              onChange={(newValue) =>
                setTimeRange({
                  ...timeRange,
                  to: dayjs(newValue).format("YYYY-MM-DD"),
                })
              }
              slotProps={{
                textField: {
                  variant: "standard",
                  InputLabelProps: { shrink: true },
                },
              }}
              minDate={dayjs("1986-01-31")}
              disabled={modelationType === "history"}
            />
          </Stack>
        </Box>
      </Popover>
    </>
  );
};
