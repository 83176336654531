import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  getInstrumentsData,
  getPortfolioData,
  instrumentsLabels,
} from "./utils";
import { ValuationData } from "src/utils/types/outputTypes";
import { useGetSecuritiesInfo } from "src/queries/useGetSecuritiesInfo";
import { useInputContext } from "src/contexts";
import { getNameFromInput } from "src/utils/functions";
import { styles } from "../styles";

const NameCellContent = ({
  isinIn,
  serviceIn,
}: {
  isinIn: string;
  serviceIn: string;
}) => {
  const { inputItems } = useInputContext();
  const { data } = useGetSecuritiesInfo({ isinIn, serviceIn });
  return (
    <span>
      {data
        ? data[0]?.name ||
          getNameFromInput({
            productId: isinIn,
            serviceId: serviceIn,
            input: inputItems,
          })
        : "--"}
    </span>
  );
};

export const InstrumentsFeesTable = ({
  data,
  filteredType,
}: {
  data: ValuationData;
  filteredType: string | number | undefined;
}) => {
  const { sortedItems } = useInputContext();
  const shownItems = filteredType ? sortedItems[filteredType] : [];

  const getValueFromPath = (obj: any, path: string): any => {
    if (path.includes("+")) {
      const paths = path.split("+").map((p) => p.trim());
      return paths.reduce((sum: number, p: string) => {
        const value = getValueFromPath(obj, p);
        if (typeof value === "number") {
          return sum + value;
        }
        return sum;
      }, 0);
    }

    return path.split(".").reduce((o: any, key: string) => {
      return o && o[key] !== "undefined" ? o[key] : undefined;
    }, obj);
  };

  return (
    <Box>
      <Typography sx={styles.title}>
        Vstupní poplatky za instrumenty v portfoliu (v měně vkladu)
      </Typography>
      <Table sx={styles.table} aria-label="simple table">
        <TableHead sx={styles.tableHeaderRow}>
          <TableRow>
            {Object.keys(instrumentsLabels).map((key) => (
              <TableCell
                key={key}
                component="th"
                scope="row"
                sx={styles.tableHeader}
              >
                {instrumentsLabels[key as keyof typeof instrumentsLabels]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.portfolio.byServices.map((service) =>
            service.byProducts.map((product) =>
              filteredType &&
              !shownItems.some(
                (item) =>
                  item.productID === product.productId &&
                  item.serviceID === service.serviceId
              ) ? null : (
                <TableRow key={`${service.serviceId}-${product.productId}`}>
                  {Object.keys(instrumentsLabels).map((key) => {
                    const instrumentData =
                      getInstrumentsData(data, product) || {};
                    return (
                      <TableCell
                        key={`${service.serviceId}-${product.productId}-${key}`}
                        component="th"
                        scope="row"
                      >
                        {key === "name" ? (
                          <NameCellContent
                            isinIn={
                              instrumentData[key as keyof typeof instrumentData]
                            }
                            serviceIn={service.serviceId}
                          />
                        ) : (
                          instrumentData[key as keyof typeof instrumentData]
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )
            )
          )}
          <TableRow>
            {Object.keys(instrumentsLabels).map((key) => {
              const instrumentData =
                getPortfolioData(data, data.portfolio) || {};
              return (
                <TableCell key={`portfolio-${key}`} component="th" scope="row">
                  {key === "name"
                    ? "Portfolio celkem"
                    : instrumentData[key as keyof typeof instrumentData]}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
