import dayjs from "dayjs";
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { defaultValues } from "src/components/form/SecurityForm/defaultValues";
import { FormTypes, PortfolioData } from "src/utils/types/inputTypes";

interface InputContextType {
  inputItems: FormTypes[];
  sortedItems: {
    [key: string]: { productID: number | string; serviceID: number | string }[];
  };
  formData: FormTypes | undefined;
  setFormData: React.Dispatch<React.SetStateAction<FormTypes | undefined>>;
  portfolioData: PortfolioData;
  setPortfolioData: (portfolioData: PortfolioData) => void;
  addInputItem: (item: FormTypes) => void;
  addInputItems: (newItems: FormTypes[]) => void;
  updateInputItem: (index: number, newItem: FormTypes) => void;
  deleteInputItem: (id: number) => void;
}

const InputContext = createContext<InputContextType | undefined>(undefined);

export const useInputContext = () => {
  const context = useContext(InputContext);
  if (!context) {
    throw new Error(
      "useInputContext musí být použito v rámci komponenty obalené InputContextProvider."
    );
  }
  return context;
};

interface InputContextProps {
  children: ReactNode;
}

export const InputContextProvider: React.FC<InputContextProps> = ({
  children,
}) => {
  const [inputItems, setInputItems] = useState<FormTypes[]>([]);
  const [sortedItems, setSortedItems] = useState<{
    [key: string]: { productID: number | string; serviceID: number | string }[];
  }>({
    security: [],
    "portfolio-strategy": [],
    dps: [],
    "transformed-fund": [],
    "building-savings": [],
    "cash-account": [],
  });
  const [formData, setFormData] = useState<FormTypes | undefined>(
    defaultValues
  );
  const [portfolioData, setPortfolioData] = useState<PortfolioData>({
    granularity: 1,
    from: "2010-01-01",
    to: dayjs(new Date()).format("YYYY-MM-DD"),
    currency: "CZK",
  });

  const addInputItems = (newInputItems: FormTypes[]) => {
    setInputItems([...newInputItems]);
  };

  const addInputItem = (inputitem: FormTypes) => {
    setInputItems([...inputItems, inputitem]);
  };

  const updateInputItem = (id: number, newInputItem: FormTypes) => {
    const updatedInputItems = [...inputItems];
    const index = updatedInputItems.findIndex((inputitem) => {
      return inputitem.id === id;
    });

    if (index !== -1) {
      updatedInputItems[index] = newInputItem;
      setInputItems(updatedInputItems);
    }
  };

  const deleteInputItem = (id: number) => {
    setInputItems(inputItems.filter((inputItem) => inputItem.id !== id));
  };

  useEffect(() => {
    const newSortedItems: {
      [key: string]: {
        productID: string;
        serviceID: string;
      }[];
    } = {
      security: [],
      "portfolio-strategy": [],
      dps: [],
      "transformed-fund": [],
      "building-savings": [],
      "cash-account": [],
    };
    inputItems.forEach((item) => {
      newSortedItems[item.type?.value as keyof typeof newSortedItems].push({
        productID: String(item?.product?.value),
        serviceID: String(item?.product?.data?.serviceIn),
      });
    });
    setSortedItems(newSortedItems);
  }, [inputItems]);

  const values: InputContextType = {
    inputItems,
    sortedItems,
    formData,
    setFormData,
    portfolioData,
    setPortfolioData,
    addInputItem,
    addInputItems,
    updateInputItem,
    deleteInputItem,
  };

  return (
    <InputContext.Provider value={values}>{children}</InputContext.Provider>
  );
};
