import * as React from "react";
import { TextField, Autocomplete } from "@mui/material";
import { OptionType } from "../../../utils/types/inputTypes";
import { useEffect } from "react";

interface Props {
  label: string;
  name: string;
  disabled?: boolean;
  options: OptionType[] | undefined;
  value: OptionType | null;
  placeHolder?: string;
  onChange: (name: string, newValue: OptionType | null) => void;
  required: boolean;
}

export const ControlledAutoComplete = ({
  label,
  name,
  disabled,
  options,
  value,
  placeHolder,
  onChange,
  required,
}: Props) => {
  return (
    <Autocomplete
      options={options || []}
      getOptionLabel={(option: OptionType) => option?.label || ""}
      disabled={disabled}
      placeholder={placeHolder || "Vyberte"}
      value={value || ("" as unknown as OptionType)}
      onChange={(e, newValue) => onChange(name, newValue)}
      sx={{ width: "100%" }}
      isOptionEqualToValue={(
        option: OptionType,
        value: OptionType | "" | undefined
      ) =>
        value === undefined ||
        value === "" ||
        value.value === "" ||
        option.value === value?.value ||
        option.value === (value as unknown as number)
      }
      noOptionsText={
        !value?.label
          ? "Žádné výsledky, vybrali jste typ modelace?"
          : "Žádné výsledky"
      }
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            value={!value ? "" : value}
            onChange={(e) =>
              onChange(name, { value: "", label: e.target.value })
            }
            placeholder={placeHolder || "Zadejte"}
            required={required}
          />
        );
      }}
    />
  );
};
