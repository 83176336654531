import { Box, CircularProgress } from "@mui/material";

export const LoadingCircle = () => {
  return (
    <Box
      sx={{
        left: "50%",
        top: "50%",
        position: "absolute",
        zIndex: 1000,
      }}
    >
      <CircularProgress
        sx={{ position: "relative", left: "-50%", top: "-50%" }}
      />
    </Box>
  );
};
