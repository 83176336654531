import { Button, Toolbar } from "@mui/material";

export const FormButtons = ({
  setOpen,
}: {
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Toolbar sx={{ justifyContent: "end", gap: "12px" }}>
      <Button
        type="reset"
        color="secondary"
        variant={"contained"}
        onClick={() => setOpen(false)}
      >
        Smazat
      </Button>
      <Button type="submit" variant={"contained"}>
        {" "}
        ULOŽIT
      </Button>
    </Toolbar>
  );
};
