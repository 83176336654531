import * as React from "react";
import { useState, ReactNode } from "react";

import { Box, Stack, Button } from "@mui/material";
import { ChevronIcon } from "../../icons";

export interface Props {
  title: string;
  multiply?: boolean;
  children: ReactNode;
  handleAddSection?: () => void;
  handleRmSection?: () => void;
  isLast?: boolean;
  isAlone?: boolean;
}

export const FormSectionWrapper = ({
  title,
  multiply,
  children,
  handleAddSection,
  handleRmSection,
  isLast,
  isAlone,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Box sx={styles.wrapper}>
      <ChevronIcon up={isExpanded} />
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Button
          disableRipple
          sx={styles.title}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {title}
        </Button>
        {multiply &&
          (isLast ? (
            <Stack direction={"row"} gap={"16px"}>
              {!isAlone && (
                <Button
                  disableRipple
                  onClick={() => {
                    if (handleRmSection) handleRmSection();
                  }}
                >
                  -
                </Button>
              )}
              <Button
                disableRipple
                onClick={() => {
                  if (handleAddSection) handleAddSection();
                }}
              >
                +
              </Button>
            </Stack>
          ) : (
            <Button
              disableRipple
              onClick={() => {
                if (handleRmSection) handleRmSection();
              }}
            >
              -
            </Button>
          ))}
      </Stack>

      {isExpanded && children}
    </Box>
  );
};

const styles = {
  wrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "24px 1fr",
    gridTemplateRows: "auto auto",
    alignItems: "center",
    columnGap: "16px",
    justifyContent: "start",
  },
  title: {
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "17px",
    textTransform: "uppercase",
    color: "#5F6C6E",
    padding: "8px 0",
    justifyContent: "start",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};
