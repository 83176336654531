/* eslint-disable no-nested-ternary */
import { useQuery } from "react-query";
import axios from "axios";

import { useAuth } from "src/contexts/AuthContext";
import { useConfig } from "src/contexts/ConfigContext";

export const useGetPortfolioStrategyType = () => {
  const { authToken } = useAuth();
  const { config } = useConfig();

  const getOptionsFromFetch = (data: any) => {
    return data?.rows?.map((row: any) => ({
      value: row.id,
      label: row.name.cs,
    }));
  };

  const fetch = async () => {
    const res = await axios.get(
      `${config.catalogUrl}${config.version}/Enum/PortfolioStrategyType`,
      {
        headers: {
          accept: "application/json",
          contentType: "application/json",
          correlationid: "382d3541-1c07-47d9-8426-d04152e82261",
          ofsauthorization: `Bearer ${authToken}`,
          userAgent: "vscode-restclient",
        },
      }
    );
    const transformedData = getOptionsFromFetch(res.data);
    return transformedData;
  };

  return useQuery("getPortfolioStrategyType", fetch, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
    enabled: !!authToken && !!config.catalogUrl,
  });
};
