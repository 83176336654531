/* eslint-disable no-nested-ternary */
import { useQuery } from "react-query";
import axios from "axios";

import QueryString from "qs";
import { useAuth } from "src/contexts/AuthContext";
import { useConfig } from "src/contexts/ConfigContext";

export const useGetSecurities = ({
  serviceIn,
  invBusPartnerIn,
  search,
  dataType,
}: {
  serviceIn?: string | number;
  invBusPartnerIn?: string | number;
  search?: string;
  dataType: "proxy" | "specific" | "";
}) => {
  const { authToken } = useAuth();
  const { config } = useConfig();

  const getOptionsFromFetch = (data: any) => {
    return data.rows.flatMap((obj: any) => {
      if (obj.securityIdentification.services.length > 0) {
        return obj.securityIdentification.services.map((service: any) => ({
          value: obj.securityIdentification.isin,
          label: `${
            obj.securityIdentification.name.cs || obj.securityIdentification.isin
          }; ${obj.securityIdentification.isin}; ${service.service.name.cs}; ${
            service.service.invBusPartner.name.cs
          }`,
          data: {
            invBusPartnerIn: service.service.invBusPartner.id,
            serviceIn: service.service.id,
            currency: service.service.currency.code,
          },
        }));
      } else
        return {
          value: obj.securityIdentification.isin,
          label: `${
            obj.securityIdentification.name.cs || obj.securityIdentification.isin
          }; ${obj.securityIdentification.isin}; ${"- -"}; ${"- -"}`,
          data: {
            invBusPartnerIn: 0,
            serviceIn: "0",
            currency: obj.currency.code,
          },
        };
    });
  };

  const fetch = async () => {
    const res = await axios.get(
      `${config.catalogUrl}${config.version}/Securities/tree`,
      {
        params: {
          isActiveIs: true,
          pageSize: 50,
          serviceIn: serviceIn || null,
          invBusPartnerIn: invBusPartnerIn || null,
          search: search || null,
          identificationsIn: dataType === "proxy" ? "PORTLAB:default" : null,
        },
        paramsSerializer: {
          serialize: (params) => {
            return QueryString.stringify(params, { skipNulls: true });
          },
        },

        headers: {
          accept: "application/json",
          contentType: "application/json",
          correlationid: "382d3541-1c07-47d9-8426-d04152e82261",
          ofsauthorization: `Bearer ${authToken}`,
          userAgent: "vscode-restclient",
        },
      }
    );
    const transformedData = getOptionsFromFetch(res.data);
    return transformedData;
  };

  return useQuery(
    `getSecurities-${invBusPartnerIn}-${serviceIn}-${dataType}`,
    fetch,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
      enabled: !!authToken && !!config.catalogUrl && !!dataType,
    }
  );
};
