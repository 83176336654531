export const chartColors = [
  "#00abbd",
  "#e11d5f",
  "#f4b907",

  "#6339b0",
  "#49a94d",
  "#f45421",

  "#ea3f34",
  "#03a2eb",
  "#f5e23a",

  "#9626aa",
  "#019083",
  "#f59100",

  "#3b4ead",
  "#86ba47",
  "#745244",
];
