import { StrategyFormProps } from "src/utils/types/inputTypes";

export const cashFlowDefault = {
  cashFlowCurrency: { value: "", label: "" },
  amount: null,
  from: null,
  to: null,
  frequency: { value: "", label: "" },
};

export const withdrawalsDefault = {
  amount: null,
  from: null,
  to: null,
  frequency: { value: "", label: "" },
};

export const defaultValues: StrategyFormProps = {
  type: { value: "portfolio-strategy", label: "Podílový fond" },
  id: null,
  invBusPartnerIn: { value: "", label: "" },
  serviceIn: { value: "", label: "" },
  PSType: { value: "", label: "" },

  product: {
    value: "",
    label: "",
    data: {
      currency: "",
      serviceIn: "",
      invBusPartnerIn: "",
      strategyType: "",
    },
  },
  portfolioStrategyEnd: { value: "", label: "" },

  portfolioCurrency: { value: "", label: "" },
  portfolioStrategyCurrency: { value: "", label: "" },

  typeOfFee: { value: "", label: "" },
  feePaymentMethod: { value: "", label: "" },
  feeDiscount: null,
  cashFlow: [cashFlowDefault],
  withdrawals: [withdrawalsDefault],
};
