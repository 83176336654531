import { TimeRangePicker } from "./TimeRangePicker";
import DownloadIcon from "@mui/icons-material/Download";
import { Stack, IconButton } from "@mui/material";
import { getInputsToPrompt } from "src/utils/functions/promptConversion/getInputsToPrompt";
import { useInputContext } from "src/contexts/InputContext";

export const TimeGranularityPicker = ({
  timeRange,
  setTimeRange,
  modelatedData,
  modelationType,
}: {
  timeRange: {
    from: any;
    to: any;
    granularity: number | undefined;
  };
  setTimeRange: (timeRange: {
    from: any;
    to: any;
    granularity: number | undefined;
  }) => void;
  modelatedData: any;
  modelationType: string;
}) => {
  const { inputItems, portfolioData } = useInputContext();
  const prompt = {
    ...getInputsToPrompt({ portfolioData, data: inputItems }),
    ...(modelationType === "future"
      ? { forecastFrom: timeRange.from }
      : { from: timeRange.from }),
    to: timeRange.to,
    granularity: timeRange.granularity || 9,
  };

  return (
    <Stack direction={"row"} gap={"12px"}>
      <TimeRangePicker
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        modelationType={modelationType}
      />
      <IconButton
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify({ input: prompt, result: modelatedData })
        )}`}
        download={`${modelationType}-modelation.json`}
      >
        <DownloadIcon color="primary" />
      </IconButton>
    </Stack>
  );
};
