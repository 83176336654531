export const ChartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
    >
      <path
        d="M1.25024 10H4.25024L8.00024 1.75L14.0002 17.5L17.7502 10H20.7502"
        stroke="#5F6C6E"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
