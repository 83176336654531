import dayjs from "dayjs";
import {
  StrategyFormProps,
  OptionType,
  SecurityFormProps,
  DPSFormProps,
  BuildingSavingsFormProps,
  CashAccountFormProps,
  FormTypes,
} from "../types/inputTypes";

export const getOptionsFromFetch = ({ data }: { data: any }) => {
  return data.rows.map((obj: any) => ({ value: obj.id, label: obj.name.cs }));
};

export const findOptionForValue = ({
  options,
  value,
}: {
  options: OptionType[];
  value: string | number | undefined | null;
}) => {
  return options?.find(
    (object: { value: string | number; label: string }) =>
      object.value === value
  );
};

export function isTypeSecurity(obj: any): obj is SecurityFormProps {
  return obj && obj.type?.value === "security";
}
export function isTypeStrategy(obj: any): obj is StrategyFormProps {
  return obj && obj.type?.value === "portfolio-strategy";
}
export function isTypeDPS(obj: any): obj is DPSFormProps {
  return obj && obj.type?.value === "dps";
}
export function isTypeTransformedFund(obj: any): obj is DPSFormProps {
  return obj && obj.type?.value === "transformed-fund";
}
export function isTypeBuildingSavings(
  obj: any
): obj is BuildingSavingsFormProps {
  return obj && obj.type?.value === "building-savings";
}
export function isTypeCashAccount(obj: any): obj is CashAccountFormProps {
  return obj && obj.type?.value === "cash-account";
}

export const currencyFormatter = (currency: string) => {
  return Intl.NumberFormat("cs-CS", {
    style: "currency",
    currency: currency,
  });
};

export const percentFormatter = () => {
  return Intl.NumberFormat("cs-CS", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const getSwitchColor = (color: string | undefined) => {
  const defaultColor = color || "#00a7b8";
  return {
    "& .MuiSwitch-switchBase": {
      color: defaultColor,
      "&:hover": {
        backgroundColor: /* defaultColor?.replace(")", ", 0.2)") */ `${defaultColor}1A`,
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: defaultColor,
    },
  };
};

export const getFractionFromPercent = (percent: number | null) => {
  if (percent) {
    return Number(percent) / 100;
  } else return 0;
};

export const getNameFromInput = ({
  productId,
  serviceId,
  input,
}: {
  productId: string | number | undefined;
  serviceId: string | number | undefined;
  input: FormTypes[];
}) => {
  const inputItem = input.find(
    (item) =>
      String(item.product.value) === String(productId) &&
      String(item.serviceIn?.value) === String(serviceId)
  );
  return inputItem?.title || `#${productId}`;
};

export const getServiceFromInput = ({
  serviceId,
  input,
}: {
  serviceId: string | number | undefined;
  input: FormTypes[];
}) => {
  const inputItem = input.find(
    (item) => String(item.serviceIn?.value) === String(serviceId)
  );
  return inputItem?.serviceIn?.label || serviceId;
};

export const getDateFromArray = ({
  dateArr,
  direction,
}: {
  dateArr: string[];
  direction: "oldest" | "newest";
}) => {
  const originalDate =
    direction === "oldest"
      ? dayjs(new Date()).subtract(1, "days")
      : dayjs(new Date()).add(1, "year");
  let resultDate = originalDate.toDate().getTime();

  dateArr.forEach((date) => {
    const dateDate = new Date(date).getTime();
    if (
      (direction === "oldest" && dateDate < resultDate) ||
      (direction === "newest" && dateDate > resultDate)
    )
      resultDate = dateDate;
  });

  return dayjs(resultDate).format("YYYY-MM-DD");
};
