import { FormTypes } from "src/utils/types/inputTypes";
import { SeriesData, Valuation } from "../../types/outputTypes";
import { getNameFromInput, getServiceFromInput } from "../helpers";
import { chartColors } from "src/utils/enums/chartColors";

export const transformPerformanceData = ({
  data,
  input,
}: {
  data: any;
  input: FormTypes[];
}) => {
  const firstValue = { x: new Date(data.from), y: 0 };
  const getAbs = (dataArr: any) => {
    const data = dataArr.map((obj: any) => ({
      x: new Date(obj.date),
      y: obj.performanceTotalAbs,
    }));
    return [firstValue, ...data];
  };

  const getRel = (dataArr: any) => {
    const data = dataArr.map((obj: any) => ({
      x: new Date(obj.date),
      y: obj.performanceTotalRel,
    }));
    return [firstValue, ...data];
  };

  const getCashFlow = (dataArr: Valuation) => {
    const data = dataArr.map((obj) => ({
      x: new Date(obj.date),
      y: obj.depositAcc,
    }));
    return data;
  };

  const palette = chartColors;
  let paletteIndex = 0;

  const portfolioDataAbs: { [key: string]: { [key: string]: SeriesData } } = {
    portfolio: {
      absolute: {
        id: "portfolio_absolute",
        label: "Absolutní výnosnost portfolia",
        data: getAbs(data.performance),
        stack: "",
        yAxisID: "valuation",
        xAxisID: "x",
        borderColor: palette[paletteIndex],
        order: 1,
        setID: "portfolio",
        setLabel: "Portfolio",
        hidden: false,
        borderWidth: 2,
      },
      cashFlow: {
        id: "portfolio_cashFlow",
        label: "Vklady a výběry",
        data: getCashFlow(data.cashFlow),
        stack: "",
        yAxisID: "valuation",
        xAxisID: "x",
        borderColor: "hsl(188, 7%, 58%)",
        order: 1,
        setID: "portfolio",
        setLabel: "Portfolio",
        hidden: false,
        borderWidth: 2,
        borderDash: [5, 2],
        stepped: true,
      },
    },
  };
  const portfolioDataRel: { [key: string]: { [key: string]: SeriesData } } = {
    portfolio: {
      relative: {
        id: "portfolio_relative",
        label: "Relativní výnosnost",
        data: getRel(data.performance),
        stack: "",
        yAxisID: "relative",
        xAxisID: "x",
        borderColor: "hsl(186, 100%, 37%)",
        order: 1,
        setID: "portfolio",
        setLabel: "Portfolio",
        hidden: false,
      },
      cashFlow: {
        id: "portfolio_cashFlow",
        label: "Vklady a výběry",
        data: getCashFlow(data.cashFlow),
        stack: "",
        yAxisID: "valuation",
        xAxisID: "x",
        borderColor: "hsl(188, 7%, 58%)",
        order: 1,
        setID: "portfolio",
        setLabel: "Portfolio",
        hidden: false,
        stepped: true,
      },
    },
  };

  const serviceDataAbs: { [key: string]: { [key: string]: SeriesData } } = {};
  const productsDataAbs: { [key: string]: { [key: string]: SeriesData } } = {};
  const serviceDataRel: { [key: string]: { [key: string]: SeriesData } } = {};
  const productsDataRel: { [key: string]: { [key: string]: SeriesData } } = {};

  data.byServices.forEach((service: any, serviceIndex: any) => {
    const serviceKey = `service_${service.serviceId}`;
    const serviceLabel = getServiceFromInput({
      serviceId: service.serviceId,
      input,
    });
    paletteIndex = (paletteIndex + 1) % palette.length;

    const absolute = {
      id: `${serviceKey}_absolute`,
      label: `${serviceLabel} - absolutní výnosnost`,
      data: getAbs(service.performance),
      stack: "",
      yAxisID: "valuation",
      xAxisID: "x",
      borderColor: palette[paletteIndex],
      order: 2,
      setID: serviceKey,
      setLabel: `${serviceLabel}`,
      hidden: false,
      borderWidth: 2,
    };
    const relative = {
      id: `${serviceKey}_relative`,
      label: `${serviceLabel} - relativní výnosnost`,
      data: getRel(service.performance),
      stack: "",
      yAxisID: "relative",
      xAxisID: "x",
      borderColor: palette[paletteIndex],
      order: 2,
      setID: serviceKey,
      setLabel: `${serviceLabel}`,
      hidden: false,
      borderWidth: 2,
    };
    const cashFlow = {
      id: `${serviceKey}_cashFlow`,
      data: getCashFlow(service.valuation),
      stack: "",
      yAxisID: "valuation",
      xAxisID: "x",
      borderColor: palette[paletteIndex],
      order: 2,
      setLabel: `${serviceLabel}`,
      label: `${serviceLabel} - vklady a výběry`,
      setID: serviceKey,
      hidden: false,
      borderDash: [5, 2],
      borderWidth: 2,
      stepped: true,
    };

    serviceDataAbs[`service_${service.serviceId}`] = {
      absolute,
      cashFlow,
    };
    serviceDataRel[`service_${service.serviceId}`] = {
      relative,
      cashFlow,
    };

    service.byProducts.forEach((product: any, productIndex: any) => {
      const productKey = `product_${service.serviceId}_${product.productId}`;
      const productLabel = getNameFromInput({
        productId: product.productId,
        serviceId: service.serviceId,
        input,
      });
      paletteIndex = (paletteIndex + 1) % palette.length;

      const absolute = {
        id: `${productKey}_absolute`,
        label: `${productLabel} - absolutní výnosnost`,
        data: getAbs(product.performance),
        stack: "",
        yAxisID: "valuation",
        xAxisID: "x",
        borderColor: palette[paletteIndex],
        order: 3,
        setID: productKey,
        hidden: false,
        setLabel: `${productLabel}`,
        borderWidth: 2,
      };
      const relative = {
        id: `${productKey}_relative`,
        label: `${productLabel} - relativní výnosnost`,
        data: getRel(product.performance),
        stack: "",
        yAxisID: "relative",
        xAxisID: "x",
        borderColor: palette[paletteIndex],
        order: 3,
        setID: productKey,
        hidden: false,
        setLabel: `${productLabel}`,
        borderWidth: 2,
      };
      const cashFlow = {
        id: `${productKey}_cashFlow`,
        setLabel: `Produkt #${product.productId}`,
        label: `Produkt #${product.productId} - vklady a výběry`,
        data: getCashFlow(product.valuation),
        stack: "",
        yAxisID: "valuation",
        xAxisID: "x",
        borderColor: palette[paletteIndex],
        order: 3,
        setID: productKey,
        hidden: false,
        borderDash: [5, 2],
        borderWidth: 2,
        stepped: true,
      };

      productsDataAbs[`product_${service.serviceId}_${product.productId}`] = {
        absolute,
        cashFlow,
      };
      productsDataRel[`product_${service.serviceId}_${product.productId}`] = {
        relative,
        cashFlow,
      };
    });
  });

  return {
    absolute: {
      portfolioData: portfolioDataAbs,
      serviceData: serviceDataAbs,
      productsData: productsDataAbs,
    },
    relative: {
      portfolioData: portfolioDataRel,
      serviceData: serviceDataRel,
      productsData: productsDataRel,
    },
  };
};
