import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";

type ConfigTypes = {
  catalogUrl: string;
  portfolioLabUrl: string;
  version: string;
  showDebugInfo: boolean;
};

// Typ pro context stavu
interface ConfigContextType {
  config: ConfigTypes;
  setConfig: (config: ConfigTypes) => void;
}

// Vytvoření kontextu s výchozími hodnotami
export const ConfigContext = createContext<ConfigContextType>({
  config: {
    catalogUrl: "",
    portfolioLabUrl: "",
    version: "",
    showDebugInfo: true,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setConfig: () => {},
});
interface ContextProps {
  children: ReactNode;
}
export const ConfigProvider: React.FC<ContextProps> = ({ children }: any) => {
  const [config, setConfig] = useState<ConfigTypes>({
    catalogUrl: "",
    portfolioLabUrl: "",
    version: "",
    showDebugInfo: true,
  });

  useEffect(() => {
    fetch("/config/config.json")
      .then((response) => response.json())
      .then((configData) => {
        setConfig(configData);
      });
  }, []);

  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};

// Hook pro snadný přístup k ConfigContextu
export const useConfig = () => useContext(ConfigContext);
