import { FormFieldProps } from "src/utils/types/inputTypes";

export const governmentContributionFields: FormFieldProps[] = [
  {
    type: "autoComplete",
    label: "Žádost o státní podporu",
    name: "governmentContribution",
    optionsKey: "governmentContributionOptions",
  },
];
