/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Stack } from "@mui/material";

import BasicSection from "../components/BasicSection";
import {
  useGetCurrency,
  useGetFeePaymentMethod,
  useGetInvBusPartner,
  useGetPortfolioStrategies,
  useGetPortfolioStrategyType,
  useGetServices,
} from "src/queries";
import RepeatableSection from "../components/RepeatableSection";
import { cashFlowFields } from "./fieldLists/cashFlowFields";
import {
  cashFlowDefault,
  defaultValues,
  withdrawalsDefault,
} from "./defaultValues";
import { withdrawalsFields } from "./fieldLists/withdrawalsFields";
import {
  feesFields,
  portfolioStrategyFields,
  serviceDefinitionFields,
  timeFields,
} from "./fieldLists";
import { useInputContext } from "src/contexts/InputContext";
import { findOptionForValue } from "src/utils/functions/helpers";
import { StrategyFormProps } from "src/utils/types/inputTypes";
import PortfolioCurrencyField from "../components/PortfolioCurrencyField";
import { FormButtons, FormSectionWrapper } from "../components";
import { advisoryProductGroups } from "src/utils/enums/advisoryProductGroups";
import { frequencyEnum } from "src/utils/enums/optionsEnums";

export const PortfolioStrategyForm = ({ setOpen }: any) => {
  const { formData, setFormData, addInputItem, updateInputItem } =
    useInputContext();

  const securityForm = useForm<StrategyFormProps>({
    defaultValues: formData || defaultValues,
  });
  const { register, handleSubmit, setValue, getValues, watch, reset } =
    securityForm;

  const handleSave = () => {
    const timeStamp = Date.now();
    const title =
      getValues("product") && getValues("product.label")
        ? getValues("product.label")
        : String(timeStamp);
    if (getValues("id")) {
      updateInputItem(Number(getValues("id")), {
        ...getValues(),
        title: title,
      });
    } else
      addInputItem({
        ...getValues(),
        id: timeStamp,
        title: title,
      });

    setFormData(undefined);
    setOpen(false);
    reset();
  };

  const onSubmit = (data: any) => {
    handleSave();
  };

  const { data: currencyOptions } = useGetCurrency();

  /*  const { data: productTypeOptions } = useGetAdvisoryProductGroup(); */
  const { data: portfolioStrategyType } = useGetPortfolioStrategyType();
  const { data: invBusPartnerOptions } = useGetInvBusPartner({
    productType: "portfolio-strategy",
  });
  const { data: servicesAndContractsOptions } = useGetServices({
    invBusPartnerIn: watch("invBusPartnerIn")?.value,
  });

  const { data: portfolioStrategiesOptions } = useGetPortfolioStrategies({
    serviceIn: watch("serviceIn")?.value,
    invBusPartnerIn: watch("invBusPartnerIn")?.value,
    portfolioStrategyTypeIn: watch("PSType")?.value,
  });

  const typeOfFeeOptions = [
    { value: "purchase", label: "Vstupní" },
    { value: "redemption", label: "Výstupní" },
  ];
  const { data: feePaymentMethodOptions } = useGetFeePaymentMethod();
  const frequencyOptions = frequencyEnum;

  // Dependant fields
  useEffect(() => {
    const PSData = watch("product")?.data;
    const PSCurrency = PSData?.currency;
    const PSService = PSData?.serviceIn;
    const PSBusPartner = PSData?.invBusPartnerIn;
    const PSType = PSData?.strategyType;
    if (PSCurrency) {
      const currencyObject = findOptionForValue({
        options: currencyOptions,
        value: PSCurrency,
      });
      if (currencyObject) {
        setValue("portfolioStrategyCurrency", currencyObject);
      }
    }
    if (!getValues("serviceIn")?.value && PSService) {
      const PSServiceObject = findOptionForValue({
        options: servicesAndContractsOptions,
        value: PSService,
      });
      if (PSServiceObject) {
        setValue("serviceIn", PSServiceObject);
      }
    }
    if (!getValues("invBusPartnerIn")?.value && PSBusPartner) {
      const PSBusPartnerObject = findOptionForValue({
        options: invBusPartnerOptions,
        value: PSBusPartner,
      });
      if (PSBusPartnerObject) {
        setValue("invBusPartnerIn", PSBusPartnerObject);
      }
    }
    if (!getValues("PSType")?.value && PSType) {
      const PSTypeObject = findOptionForValue({
        options: portfolioStrategyType,
        value: PSType,
      });
      if (PSTypeObject) {
        setValue("PSType", PSTypeObject);
      }
    }
  }, [watch("product")]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="32px">
        <BasicSection
          title={"DEFINICE INVESTIČNÍ SLUŽBY"}
          form={securityForm}
          options={{
            productTypeOptions: advisoryProductGroups,
            portfolioStrategyType,
            invBusPartnerOptions,
            servicesAndContractsOptions,
          }}
          fields={serviceDefinitionFields}
        />

        <BasicSection
          title={"Výběr Portfolio strategie"}
          form={securityForm}
          options={{
            portfolioStrategiesOptions,
          }}
          fields={portfolioStrategyFields}
        />

        {/*   <BasicSection
          title={"Referenční měna portfolia"}
          form={securityForm}
          options={{
            currencyOptions,
          }}
          fields={portfolioCurrencyFields}
        /> */}
        <FormSectionWrapper title={"Referenční měna portfolia"}>
          <Box sx={{ width: "100%", gridColumn: 2, gap: "20px" }}>
            <PortfolioCurrencyField />
          </Box>
        </FormSectionWrapper>

        <BasicSection
          title={"Časování strategie / linie"}
          form={securityForm}
          options={{
            portfolioStrategiesOptions,
          }}
          fields={
            watch("PSType")?.value === 1
              ? timeFields.filter(
                  (field) => field.name !== "portfolioStrategyEnd"
                )
              : timeFields
          }
        />

        <BasicSection
          title={"Poplatky & způsob úhrady"}
          form={securityForm}
          options={{
            typeOfFeeOptions,
            feePaymentMethodOptions,
          }}
          fields={
            watch("typeOfFee")?.value === "redemption"
              ? feesFields.filter((field) => field.name !== "feePaymentMethod")
              : feesFields
          }
        />

        <RepeatableSection
          name={"cashFlow"}
          title={"Vklady / nákupy"}
          form={securityForm}
          options={{
            currencyOptions,
            frequencyOptions,
          }}
          fields={cashFlowFields}
          defaultValues={cashFlowDefault}
        />

        <RepeatableSection
          name={"withdrawals"}
          title={"Výběry / prodej"}
          form={securityForm}
          options={{
            frequencyOptions,
          }}
          fields={withdrawalsFields}
          defaultValues={withdrawalsDefault}
        />

        <FormButtons setOpen={setOpen} />
      </Stack>
    </form>
  );
};
