import { SecurityFormProps } from "../../types/inputTypes";
import { getFractionFromPercent } from "../helpers";
import { convertCashFlow } from "./promptConversionHelpers";

export const getSecurityPrompt = ({
  product,
}: {
  product: SecurityFormProps;
}) => {
  const transformedCashFlow = product.cashFlow?.map((obj) =>
    convertCashFlow({
      cashFlow: obj,
      type: 1,
      instrumentCurrency: product.product?.data?.currency,
    })
  );
  const transformedWithdrawals = product?.withdrawals?.map((obj) =>
    convertCashFlow({
      cashFlow: obj,
      type: 3,
      instrumentCurrency: product?.product?.data?.currency,
    })
  );
  const cashFlows = [
    ...(transformedCashFlow
      ? transformedCashFlow.filter((value) => value !== null)
      : []),
    ...(transformedWithdrawals
      ? transformedWithdrawals.filter((value) => value !== null)
      : []),
  ];

  const transformedProduct = {
    type: product.type?.value,
    isin: product.product.value,
    fees:
      product.typeOfFee?.value && product.feePaymentMethod?.value
        ? [
            {
              type: product.typeOfFee?.value,
              feeType: product.feePaymentMethod?.value,
              discount: getFractionFromPercent(product.feeDiscount),
            },
          ]
        : [],
    cashFlow: cashFlows,
  };

  return transformedProduct;
};
