export const frequencyEnum = [
  { value: 1, label: "Denně" },
  { value: 2, label: "Týdně" },
  { value: 3, label: "Měsíčně" },
  { value: 4, label: "Čtvrtletně" },
  { value: 5, label: "Ročně" },
];

export const modelationTypeOptions = [
  { value: "specific", label: "Konkrétní" },
  { value: "proxy", label: "Obecný" },
];
