import { Box, TextField } from "@mui/material";
import { ValuationData } from "src/utils/types/outputTypes";
import PortfolioCurrencyField from "../../form/components/PortfolioCurrencyField";
import { getPortfolioData, portfolioLabels } from "./utils";

export const PortfolioSummary = ({ data }: { data: ValuationData }) => {
  const tableData = getPortfolioData(data) || {};

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "12px",
        padding: "24px",
        boxShadow: 3,
        borderRadius: 2,
        marginBottom: 2,
      }}
    >
      {Object.keys(portfolioLabels).map((key) =>
        key === "currency" ? (
          <PortfolioCurrencyField
            key={key}
            sx={{
              backgroundColor: "#fbfbfb",
              border: "8px solid #fbfbfb",
              borderBottom: "none",
            }}
          />
        ) : (
          <TextField
            key={key}
            label={portfolioLabels[key as keyof typeof portfolioLabels]}
            disabled={true}
            value={tableData[key as keyof typeof tableData] || ""}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            sx={{
              "-webkit-text-fill-color": "#859294 !important",
              ".Mui-disabled": {
                "-webkit-text-fill-color": "#859294 !important",
              },
              backgroundColor: "#fbfbfb",
              border: "8px solid #fbfbfb",
              borderBottom: "none",
            }}
          />
        )
      )}
    </Box>
  );
};
