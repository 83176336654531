import { FormFieldProps } from "src/utils/types/inputTypes";

export const portfolioStrategyFields: FormFieldProps[] = [
  {
    type: "autoComplete",
    label: "Název produktu",
    name: "product",
    optionsKey: "portfolioStrategiesOptions",
    required: true,
  },
];
