import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { getInstrumentsData, instrumentsLabels } from "./utils";
import { ValuationData } from "src/utils/types/outputTypes";
import { useGetSecuritiesInfo } from "src/queries/useGetSecuritiesInfo";
import { useInputContext } from "src/contexts";
import { styles } from "../styles";

const NameCellContent = ({
  isinIn,
  serviceIn,
}: {
  isinIn: string;
  serviceIn: string;
}) => {
  const { data } = useGetSecuritiesInfo({ isinIn, serviceIn });
  return <span>{data ? data[0]?.name || "Obecný fond" : "--"}</span>;
};

export const CashAccountTable = ({
  data,
  filteredType,
}: {
  data: ValuationData;
  filteredType: string | number | undefined;
}) => {
  const { sortedItems } = useInputContext();
  const shownItems = filteredType ? sortedItems[filteredType] : [];
  const getValueFromPath = (obj: any, path: string): any => {
    if (path.includes("+")) {
      const paths = path.split("+").map((p) => p.trim());
      return paths.reduce((sum: number, p: string) => {
        const value = getValueFromPath(obj, p);
        if (typeof value === "number") {
          return sum + value;
        }
        return sum;
      }, 0);
    }

    return path.split(".").reduce((o: any, key: string) => {
      return o && o[key] !== "undefined" ? o[key] : undefined;
    }, obj);
  };

  return (
    <Box>
      <Typography sx={styles.title}>
        Instrumenty v portfoliu (v referenční měně ptf.)
      </Typography>
      <Table sx={styles.table} aria-label="simple table">
        <TableHead sx={styles.tableHeaderRow}>
          <TableRow>
            {Object.keys(instrumentsLabels).map((key) => (
              <TableCell
                key={key}
                component="th"
                scope="row"
                sx={styles.tableHeader}
              >
                {instrumentsLabels[key as keyof typeof instrumentsLabels]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.portfolio.byServices.map((service) => {
            return filteredType &&
              !shownItems.some(
                (item) => item.serviceID === service.serviceId
              ) ? null : (
              <TableRow key={`${service.serviceId}`}>
                {Object.keys(instrumentsLabels).map((key) => {
                  const instrumentData =
                    getInstrumentsData(data, service) || {};
                  return (
                    <TableCell
                      key={`${service.serviceId}-${key}`}
                      component="th"
                      scope="row"
                    >
                      {key === "name" ? (
                        <NameCellContent
                          isinIn={
                            instrumentData[key as keyof typeof instrumentData]
                          }
                          serviceIn={service.serviceId}
                        />
                      ) : (
                        instrumentData[key as keyof typeof instrumentData]
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
