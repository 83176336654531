import { FormTypes } from "src/utils/types/inputTypes";
import { FutureData, SeriesData, Valuation } from "../../types/outputTypes";
import { getNameFromInput, getServiceFromInput } from "../helpers";

export const transformFutureData = ({
  data,
  input,
}: {
  data: FutureData;
  input: FormTypes[];
}) => {
  const getValuation = (dataArr: Valuation) => {
    const data = dataArr.map((obj) => ({
      x: new Date(obj.date),
      y: obj.value,
    }));
    return data;
  };

  const portfolioData: { [key: string]: { [key: string]: SeriesData } } = {
    portfolio: {},
  };
  const serviceData: { [key: string]: { [key: string]: SeriesData } } = {};
  const productsData: { [key: string]: { [key: string]: SeriesData } } = {};

  const scenarioEnum = {
    negative: { label: "Negativní scénář", color: "#f33b12" },
    neutral: { label: "Neutrální scénář", color: "#f9b429" },
    positive: { label: "Pozitivní scénář", color: "#00a7b8" },
  };

  const scenarios: ("negative" | "neutral" | "positive")[] = [
    "negative",
    "neutral",
    "positive",
  ];

  scenarios.forEach((key) => {
    portfolioData.portfolio[key] = {
      id: `portfolio_${key}`,
      label: `${scenarioEnum[key as keyof typeof scenarioEnum].label}`,
      data: getValuation(
        data.forecast[key as keyof typeof data.forecast].portfolio.valuation
      ),
      stack: "",
      borderColor: scenarioEnum[key as keyof typeof scenarioEnum].color,
      order: 2,
      yAxisID: "valuation",
      setID: "portfolio",
      setLabel: "Portfolio",
      hidden: false,
      borderWidth: 2,
    };

    data.forecast[
      key as keyof typeof data.forecast
    ].portfolio.byServices.forEach((service) => {
      const serviceKey = `service_${service.serviceId}`;
      const serviceLabel = getServiceFromInput({
        serviceId: service.serviceId,
        input,
      });
      serviceData[serviceKey] = serviceData[serviceKey] || {};

      serviceData[serviceKey][key] = {
        id: `service_${service.serviceId}_${key}`,
        label: `${scenarioEnum[key as keyof typeof scenarioEnum].label}`,
        data: getValuation(service.valuation),
        stack: "",
        borderColor: scenarioEnum[key as keyof typeof scenarioEnum].color,
        order: 2,
        yAxisID: "valuation",
        setID: serviceKey,
        setLabel: `${serviceLabel}`,
        hidden: false,
        borderWidth: 2,
      };

      service.byProducts.forEach((product) => {
        const productKey = `product_${service.serviceId}_${product.productId}`;
        const productLabel = getNameFromInput({
          productId: product.productId,
          serviceId: service.serviceId,
          input,
        });
        productsData[productKey] = productsData[productKey] || {};
        productsData[productKey][key] = {
          id: `${productKey}_${key}`,
          label: `${scenarioEnum[key as keyof typeof scenarioEnum].label}`,
          data: getValuation(product.valuation),
          stack: "",
          borderColor: scenarioEnum[key as keyof typeof scenarioEnum].color,
          order: 3,
          yAxisID: "valuation",
          setID: productKey,
          hidden: false,
          setLabel: `${productLabel}`,
          borderWidth: 2,
        };
      });
    });
  });

  return {
    data: {
      portfolioData,
      serviceData,
      productsData,
    },
  };
};
