export const TableIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.25 0.833252H1.75004C1.50693 0.833252 1.27377 0.929829 1.10186 1.10174C0.929951 1.27365 0.833374 1.5068 0.833374 1.74992V18.2499C0.833374 18.493 0.929951 18.7262 1.10186 18.8981C1.27377 19.07 1.50693 19.1666 1.75004 19.1666H18.25C18.4932 19.1666 18.7263 19.07 18.8982 18.8981C19.0701 18.7262 19.1667 18.493 19.1667 18.2499V1.74992C19.1667 1.5068 19.0701 1.27365 18.8982 1.10174C18.7263 0.929829 18.4932 0.833252 18.25 0.833252ZM6.33337 17.3333H2.66671V13.6666H6.33337V17.3333ZM6.33337 11.8333H2.66671V8.16658H6.33337V11.8333ZM6.33337 6.33325H2.66671V2.66659H6.33337V6.33325ZM11.8334 17.3333H8.16671V13.6666H11.8334V17.3333ZM11.8334 11.8333H8.16671V8.16658H11.8334V11.8333ZM11.8334 6.33325H8.16671V2.66659H11.8334V6.33325ZM17.3334 17.3333H13.6667V13.6666H17.3334V17.3333ZM17.3334 11.8333H13.6667V8.16658H17.3334V11.8333ZM17.3334 6.33325H13.6667V2.66659H17.3334V6.33325Z"
        fill="#009FB5"
      />
    </svg>
  );
};
