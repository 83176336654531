import { FormTypes } from "src/utils/types/inputTypes";
import {
  CashFlow,
  SeriesData,
  Valuation,
  ValuationData,
} from "../../types/outputTypes";
import { generateColorPairs } from "../createPalette";
import { getNameFromInput, getServiceFromInput } from "../helpers";
import { chartColors } from "src/utils/enums/chartColors";

export const transformHistoryData = ({
  data,
  input,
}: {
  data: ValuationData;
  input: FormTypes[];
}) => {
  const firstValue = { x: new Date(data.from), y: 0 };

  const getValuation = (dataArr: Valuation) => {
    const data = dataArr.map((obj) => ({
      x: new Date(obj.date),
      y: obj.value,
    }));
    return [firstValue, ...data];
  };

  const getCashFlow = (dataArr: Valuation) => {
    const data = dataArr.map((obj) => ({
      x: new Date(obj.date),
      y: obj.depositAcc,
    }));
    return data;
  };

  const getNavs = (dataArr: Valuation) => {
    const data = dataArr.map((obj) => ({
      x: new Date(obj.date),
      y: obj.value,
    }));
    return data;
  };

  const palette = chartColors;
  let paletteIndex = 0;

  const portfolioData: { [key: string]: { [key: string]: SeriesData } } = {
    portfolio: {
      valuation: {
        id: "portfolio_valuation",
        label: "Hodnota portfolia",
        data: getValuation(data.portfolio.valuation),
        stack: "",
        yAxisID: "valuation",
        xAxisID: "x",
        borderColor: palette[paletteIndex],
        order: 1,
        setID: "portfolio",
        setLabel: "Portfolio",
        hidden: false,
        borderWidth: 2,
      },
      cashFlow: {
        id: "portfolio_cashFlow",
        label: "Vklady a výběry",
        data: getCashFlow(data.portfolio.valuation),
        stack: "",
        yAxisID: "valuation",
        xAxisID: "x",
        borderColor: "hsl(188, 7%, 58%)",
        order: 1,
        setID: "portfolio",
        setLabel: "Portfolio",
        hidden: false,
        borderWidth: 2,
        borderDash: [5, 2],
        stepped: true,
      },
    },
  };

  const serviceData: { [key: string]: { [key: string]: SeriesData } } = {};
  const productsData: { [key: string]: { [key: string]: SeriesData } } = {};

  data.portfolio.byServices.forEach((service, serviceIndex) => {
    paletteIndex = (paletteIndex + 1) % palette.length;
    const serviceKey = `service_${service.serviceId}`;
    const valuation = {
      id: `${serviceKey}_valuation`,

      label: `${getServiceFromInput({
        serviceId: service.serviceId,
        input,
      })} - hodnota`,
      data: getValuation(service.valuation),
      stack: "",
      yAxisID: "valuation",
      xAxisID: "x",
      borderColor: palette[paletteIndex],
      order: 2,
      setID: serviceKey,
      setLabel: `${getServiceFromInput({
        serviceId: service.serviceId,
        input,
      })}`,
      hidden: false,
      borderWidth: 2,
    };
    const cashFlow = {
      id: `${serviceKey}_cashFlow`,
      data: getCashFlow(service.valuation),
      stack: "",
      yAxisID: "valuation",
      xAxisID: "x",
      borderColor: palette[paletteIndex],
      order: 2,
      setLabel: `${getServiceFromInput({
        serviceId: service.serviceId,
        input,
      })}`,
      label: `${getServiceFromInput({
        serviceId: service.serviceId,
        input,
      })} - vklady a výběry`,
      setID: serviceKey,
      hidden: false,
      borderDash: [5, 2],
      borderWidth: 2,
      stepped: true,
    };

    serviceData[serviceKey] = {
      valuation,
      cashFlow,
    };

    service.byProducts.forEach((product, productIndex) => {
      paletteIndex = (paletteIndex + 1) % palette.length;
      const productKey = `product_${service.serviceId}_${product.productId}`;
      const valuation = {
        id: `${productKey}_valuation`,

        label: `${getNameFromInput({
          productId: product.productId,
          serviceId: service.serviceId,
          input,
        })} - hodnota`,
        data: getValuation(product.valuation),
        stack: "",
        yAxisID: "valuation",
        xAxisID: "x",
        borderColor: palette[paletteIndex],
        order: 3,
        setID: productKey,
        hidden: false,
        setLabel: `${getNameFromInput({
          productId: product.productId,
          serviceId: service.serviceId,
          input,
        })}`,
        borderWidth: 2,
      };
      const cashFlow = {
        id: `${productKey}_cashFlow`,
        setLabel: `${getNameFromInput({
          productId: product.productId,
          serviceId: service.serviceId,
          input,
        })}`,
        label: `${getNameFromInput({
          productId: product.productId,
          serviceId: service.serviceId,
          input,
        })} - vklady a výběry`,
        data: getCashFlow(product.valuation),
        stack: "",
        yAxisID: "valuation",
        xAxisID: "x",
        borderColor: palette[paletteIndex],
        order: 3,
        setID: productKey,
        hidden: false,
        borderDash: [5, 2],
        borderWidth: 2,
        stepped: true,
      };
      const nav = {
        id: `${productKey}_nav`,
        setLabel: `${getNameFromInput({
          productId: product.productId,
          serviceId: service.serviceId,
          input,
        })}`,
        label: `${getNameFromInput({
          productId: product.productId,
          serviceId: service.serviceId,
          input,
        })} - Net Asset Value`,
        data: getNavs(product.navs),
        stack: "",
        yAxisID: "navs",
        xAxisID: "x",
        borderColor: palette[paletteIndex],
        order: 3,
        setID: productKey,
        hidden: false,
        borderDash: [2, 2],
        borderWidth: 2,
        borderJoinStyle: "round",
      };

      productsData[`product_${service.serviceId}_${product.productId}`] = {
        valuation,
        cashFlow,
        ...(product.navs?.length > 0 ? { nav } : {}),
      };
    });
  });

  return {
    minMax_X: { min: new Date(data.from), max: new Date(data.to) },
    data: {
      portfolioData,
      serviceData,
      productsData,
    },
  };
};
