import { BuildingSavingsFormProps } from "src/utils/types/inputTypes";
import { convertCashFlow } from "./promptConversionHelpers";
import { getFractionFromPercent } from "../helpers";

export const getBuildingSavingsPrompt = ({
  product,
}: {
  product: BuildingSavingsFormProps;
}) => {
  const transformedClientCashFlow = product.cashFlow?.map((obj) =>
    convertCashFlow({
      cashFlow: obj,
      type: 1,
      instrumentCurrency: product.product.data.currency,
    })
  );

  const cashFlows = [
    ...(transformedClientCashFlow
      ? transformedClientCashFlow.filter((value) => value !== null)
      : []),
  ];

  const transformedProduct = {
    type: product.type?.value,
    id: product.product.value,
    cashFlow: cashFlows,
    contributionRate: getFractionFromPercent(product.interestRate),
    interestRate: getFractionFromPercent(product.depositInterestRate),
    maxContributionValue: product.maxContributionValue,
  };

  return transformedProduct;
};
