import { BrowserRouter } from "react-router-dom";
import { InputContextProvider } from "./contexts/InputContext";
import { QueryClient, QueryClientProvider } from "react-query";
import PageWrapper from "./pages/DataWrapper";
import { AuthProvider } from "./contexts/AuthContext";
import { ConfigProvider } from "./contexts/ConfigContext";
import { ThemeProvider } from "@emotion/react";
import { clickerTheme } from "./theme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/cs";
import { LocalizationProvider } from "@mui/x-date-pickers";

const queryClient = new QueryClient();

const App = () => {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={clickerTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
              <ConfigProvider>
                <AuthProvider>
                  <InputContextProvider>
                    <PageWrapper />
                  </InputContextProvider>
                </AuthProvider>
              </ConfigProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
