import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";

// Typ pro context stavu
interface AuthContextType {
  authToken: string | null;
  setAuthToken: (token: string) => void;
}

// Vytvoření kontextu s výchozími hodnotami
export const AuthContext = createContext<AuthContextType>({
  authToken: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAuthToken: () => {},
});
interface InputContextProps {
  children: ReactNode;
}
export const AuthProvider: React.FC<InputContextProps> = ({
  children,
}: any) => {
  const [authToken, setAuthToken] = useState<string | null>(null);

  useEffect(() => {
    // Načtení tokenu z localStorage při mountu
    const token = localStorage.getItem("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  // Aktualizace localStorage kdykoliv se změní authToken
  useEffect(() => {
    if (authToken) {
      localStorage.setItem("authToken", authToken);
    }
  }, [authToken]);

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook pro snadný přístup k AuthContextu
export const useAuth = () => useContext(AuthContext);
