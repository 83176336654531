import { DPSFormProps, StrategyFormProps } from "src/utils/types/inputTypes";

export const cashFlowDefault = {
  cashFlowCurrency: { value: "", label: "" },
  amount: null,
  from: null,
  to: null,
  frequency: { value: "", label: "" },
};

export const withdrawalsDefault = {
  amount: null,
  from: null,
  to: null,
  frequency: { value: "", label: "" },
};

export const defaultValues: DPSFormProps = {
  type: { value: "transformed-fund", label: "Transformovaný fond" },
  id: null,
  invBusPartnerIn: { value: "", label: "" },
  serviceIn: { value: "0", label: "Obecná služba" },
  modelationType: { value: "", label: "" },
  product: {
    value: "",
    label: "",
    data: {
      currency: "",
      serviceIn: "",
      invBusPartnerIn: "",
    },
  },

  portfolioCurrency: { value: "", label: "" },

  clientCashFlow: [cashFlowDefault],
  employerCashFlow: [cashFlowDefault],
  withdrawals: [withdrawalsDefault],
};
