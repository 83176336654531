import { FormFieldProps } from "src/utils/types/inputTypes";

export const cashFlowFields: FormFieldProps[] = [
  {
    type: "autoComplete",
    label: "Měna vkladu",
    name: "cashFlowCurrency",
    optionsKey: "currencyOptions",
  },
  {
    type: "textField",
    label: "Výše vkladu (nákup investice)",
    name: "amount",
    fieldType: "number",
  },
  { type: "datePick", label: "Od (datum)", name: "from" },
  { type: "datePick", label: "Do (datum)", name: "to" },
  {
    type: "autoComplete",
    label: "Frekvence vkladu",
    name: "frequency",
    optionsKey: "frequencyOptions",
  },
];
