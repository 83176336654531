export const ChevronIcon = ({ up }: { up?: boolean }) => {
  return (
    <span
      style={{
        display: "grid",
        transform: up ? "" : "rotate(180deg)",
      }}
    >
      <svg
        width="24"
        height="27"
        viewBox="0 0 24 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.46997 13.74L12 17.26L15.53 13.74"
          stroke="#979797"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
