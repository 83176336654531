import { currencyFormatter } from "src/utils/functions/helpers";
import { ServiceDataType, ValuationData } from "src/utils/types/outputTypes";

export const instrumentsLabels = {
  name: "Název služby",
  portfolioCurrency: "Referenční měna portfolia",
  clientDeposits: "Vklady celkem klient",
  employerContributions: "Vklady celkem zaměstnavatel",
  investments: "Čisté vklady (nákupy) celkem",
};

export const getInstrumentsData = (
  portfolioData: ValuationData,
  data: ServiceDataType
) => {
  if (data?.summary) {
    return {
      name: data.serviceId,
      portfolioCurrency: portfolioData.portfolio.currency,
      clientDeposits: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.clientDeposits),
      employerContributions: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.employerContributions),
      investments: currencyFormatter(portfolioData?.portfolio?.currency).format(
        data.summary.investments
      ),
    };
  }
};
