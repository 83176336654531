import { createTheme } from "@mui/material/styles";
import { csCZ as coreCsCZ } from "@mui/material/locale";
import { csCZ } from "@mui/x-date-pickers/locales";

const primaryBlue = "#00abbd";
const textAction = "#009FB5";
const textPrimary = "#111827";
const textSecondary = "#5F6C6E";
const backgroundGray = "#ebedee";

export const clickerTheme = createTheme(
  {
    palette: {
      primary: { main: primaryBlue },
      secondary: { main: backgroundGray },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            "&.MuiButton-text": {
              backgroundColor: "transparent",
              color: textPrimary,
              ":hover": {
                backgroundColor: "transparent",
                color: `${textAction} !important`,
              },
            },
            "&.MuiButton-contained": {
              boxShadow: "none",
              color: "#fff",
              borderRadius: "3px",
              ":hover": {
                backgroundColor: "#008998",
              },
            },
            "&.MuiButton-containedSecondary": {
              color: textSecondary,

              ":hover": {
                backgroundColor: "#d5d7d8",
              },
            },
          },
        },
        defaultProps: {
          disableFocusRipple: true,
          disableRipple: true,
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            "&.MuiChip-filled": {
              backgroundColor: primaryBlue,
              color: "#fff",
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: backgroundGray,
            "&.Mui-selected": {
              backgroundColor: primaryBlue,
              color: "#fff",
              zIndex: "2",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            ".MuiTabs-flexContainer": {
              gap: "4px",
            },
            ".MuiTabs-indicator": {
              height: "100%",
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: { cursor: "default" },
        },
      },
    },
  },
  csCZ,
  coreCsCZ
);
