import { FormFieldProps } from "src/utils/types/inputTypes";

export const timeFields: FormFieldProps[] = [
  {
    type: "autoComplete",
    label: "Počáteční strategie / linie",
    name: "product",
    optionsKey: "portfolioStrategiesOptions",
    disabled: true,
  },
  {
    type: "autoComplete",
    label: "Konečná strategie / linie",
    name: "portfolioStrategyEnd",
    optionsKey: "portfolioStrategiesOptions",
  },
];
