import * as React from "react";
import { Stack } from "@mui/material";
import { ControlledAutoComplete, ControlledTextField } from ".";

import { Dayjs } from "dayjs";
import { ControlledDatePick } from "./ControlledDatePick";
import {
  FormFieldProps,
  OptionType,
  SecurityFormProps,
  StrategyFormProps,
  ValueType,
} from "../../../utils/types/inputTypes";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";
import { useInputContext } from "src/contexts/InputContext";

const SectionContent = ({
  fields,
  form,
  options,
  onChangeField,
  fieldValues,
}: {
  fields: FormFieldProps[];
  form: UseFormReturn<FieldValues, any, undefined>;
  options: {
    [key: string]: OptionType[] | undefined;
  };
  onChangeField: (name: string, newValue: any) => void;
  fieldValues?: {
    [key: string]: OptionType | null | string | number;
  };
}) => {
  const { formData, setFormData } = useInputContext();
  const { getValues, control, setValue, watch } = form;

  return (
    <Stack
      sx={{ width: "100%", gridColumn: 2, gap: "20px" }}
      key={fields[0].name}
    >
      {fields.map((field: FormFieldProps) => {
        switch (field.type) {
          case "autoComplete":
            return (
              <Controller
                key={field.name}
                control={control}
                name={field.name}
                rules={{ required: !!field.required }}
                render={() => (
                  <ControlledAutoComplete
                    label={field.label}
                    name={field.name}
                    value={
                      fieldValues
                        ? fieldValues[field.name as keyof typeof fieldValues]
                        : watch(field.name)
                    }
                    onChange={(name, newValue) => {
                      onChangeField(name, newValue);
                    }}
                    options={options[field.optionsKey as keyof typeof options]}
                    placeHolder={field.placeHolder}
                    disabled={field.disabled}
                    required={!!field.required}
                  />
                )}
              />
            );
          case "textField":
            return (
              <Controller
                key={field.name}
                control={control}
                name={field.name}
                rules={{ required: !!field.required }}
                render={() => (
                  <ControlledTextField
                    label={field.label}
                    name={field.name}
                    value={
                      fieldValues
                        ? fieldValues[field.name as keyof typeof fieldValues]
                        : getValues(field.name)
                    }
                    onChangeField={(name, newValue) =>
                      onChangeField(name, newValue)
                    }
                    type={field.fieldType}
                    placeHolder={field.placeHolder}
                    disabled={field.disabled}
                    required={!!field.required}
                  />
                )}
              />
            );
          case "datePick":
            return (
              <Controller
                key={field.name}
                control={control}
                name={field.name}
                rules={{ required: !!field.required }}
                render={() => (
                  <ControlledDatePick
                    key={field.name}
                    name={field.name}
                    value={
                      fieldValues
                        ? fieldValues[field.name as keyof typeof fieldValues]
                        : getValues(field.name)
                    }
                    label={field.label}
                    onChange={(name, newValue: Dayjs | null) =>
                      onChangeField(name, newValue)
                    }
                    disabled={field.disabled}
                    required={!!field.required}
                  />
                )}
              />
            );
          default:
            return null;
        }
      })}
    </Stack>
  );
};

export default SectionContent;
