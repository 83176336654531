import { FormFieldProps } from "src/utils/types/inputTypes";

export const interestConditionsFields: FormFieldProps[] = [
  {
    type: "textField",
    label: "Doba trvání SS (vazací doba v letech)",
    name: "savingDuration",
    fieldType: "number",
    disabled: true,
  },
  {
    type: "textField",
    label: "Úroková sazba pro výpočet SS",
    name: "interestRate",
  },
  {
    type: "textField",
    label: "Úroková sazba z vkladů (roční v %)",
    name: "depositInterestRate",
  },
  {
    type: "textField",
    label: "Maximální příspěvěk",
    name: "maxContributionValue",
  },
  {
    type: "datePick",
    label: "Datum předčasného ukončení SS",
    name: "earlyTerminationDate",
    disabled: true,
  },
];
