// src/components/AuthTokenDialog.tsx
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import {
  TextField,
  Accordion,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Stack,
  DialogTitle,
  IconButton,
  AccordionSummary,
  AccordionDetails,
  Box,
  AccordionProps,
  AccordionSummaryProps,
} from "@mui/material";
import { useInputContext } from "src/contexts";
import styled from "@emotion/styled";

export const LoadInputDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { addInputItems } = useInputContext();
  const savedInputs = JSON.parse(localStorage.getItem("savedInputs") || "{}");
  const [currentSavedItems, setCurrentSavedItems] = useState({});
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleDelete = (name: string) => {
    const filteredInputs = { ...savedInputs };
    delete filteredInputs[name];
    localStorage.setItem("savedInputs", JSON.stringify(filteredInputs));
    setCurrentSavedItems(filteredInputs);
  };

  const handleLoad = (name: string) => {
    const pickedInput = savedInputs[name];
    addInputItems(pickedInput.savedItems);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setCurrentSavedItems(
        JSON.parse(localStorage.getItem("savedInputs") || "{}")
      );
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Načíst inputy z localStorage</DialogTitle>
      <DialogContent sx={{ width: "500px" }}>
        <Stack gap="24px">
          {Object.keys(currentSavedItems)?.length > 0 ? (
            <Typography fontWeight={600}> Uložené položky: </Typography>
          ) : (
            <Typography fontWeight={600}> Žádné uložené položky </Typography>
          )}

          <Stack>
            {Object.keys(currentSavedItems).map((name) => {
              const key = savedInputs[name].key;
              return (
                <Accordion
                  expanded={expanded === key}
                  onChange={handleChange(key)}
                  key={key}
                  disableGutters
                  elevation={0}
                  square
                  sx={{
                    border: "1px solid #e2e5e5",
                    "&:not(:last-child)": {
                      borderBottom: 0,
                    },
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    expandIcon={
                      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
                    }
                    sx={{
                      backgroundColor: "#f6f7f7",
                      flexDirection: "row-reverse",
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(90deg)",
                      },
                      "& .MuiAccordionSummary-content": {
                        marginLeft: "12px",
                      },
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Typography>{name}</Typography>

                      <Stack direction="row" gap={"12px"} alignItems={"center"}>
                        <IconButton onClick={() => handleLoad(name)}>
                          <FileUploadIcon color="primary" />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(name)}>
                          <DeleteIcon color="primary" />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack direction="row" flexWrap={"wrap"} gap="12px">
                      {savedInputs[name].savedItems.map((item: any) => (
                        <Chip
                          key={item.id}
                          label={item.title}
                          variant={"outlined"}
                        />
                      ))}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  );
};
