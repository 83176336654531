import { useInputContext } from "src/contexts/InputContext";
import { Chip, Stack } from "@mui/material";
import {
  SecurityFormProps,
  StrategyFormProps,
} from "src/utils/types/inputTypes";

export function isSecurityFormProps(
  item: StrategyFormProps | SecurityFormProps
): item is SecurityFormProps {
  return (item as SecurityFormProps).product !== undefined;
}
export const SavedFundsList = ({
  setOpen,
}: {
  setOpen: (open: boolean) => void;
}) => {
  const { inputItems, deleteInputItem, setFormData } = useInputContext();

  const handleDelete = (id: number | null) => {
    if (typeof id === "number") {
      deleteInputItem(id);
    }
  };

  return (
    <Stack gap="12px" direction="row" sx={{ padding: "12px 0px" }}>
      {inputItems.map((item) => (
        <Chip
          key={item.id}
          onClick={() => {
            setOpen(true);
            setFormData(item);
          }}
          variant="outlined"
          label={item.title}
          onDelete={() => handleDelete(item.id)}
          sx={{ width: "fit-content" }}
        />
      ))}
    </Stack>
  );
};
