import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { ChevronIcon } from "../../icons";
import { useInputContext } from "src/contexts/InputContext";
import {
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { OptionType } from "src/utils/types/inputTypes";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const ScenariosPicker = ({
  pickedScenario,
  setPickedScenario,
}: {
  pickedScenario: string;
  setPickedScenario: (pickedScenario: string) => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(null);
    } else if (event.key === "Escape") {
      setAnchorEl(null);
    }
  }

  const options = [
    {
      value: "positive",
      label: "Pozitivní",
    },
    {
      value: "neutral",
      label: "Neutrální",
    },
    { value: "negative", label: "Negativní" },
  ];

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
        endIcon={<ChevronIcon up={!open} />}
      >
        {options.find((option) => pickedScenario === option.value)?.label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}
          >
            {options.map((option: OptionType) => (
              <MenuItem
                key={option.value}
                onClick={() => {
                  setPickedScenario(String(option.value));
                  handleClose();
                }}
              >
                <ListItemIcon>
                  {pickedScenario === option.value ? (
                    <CheckCircleOutlineIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
                <ListItemText> {option.label}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Popover>
    </div>
  );
};
