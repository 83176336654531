import { FormFieldProps } from "src/utils/types/inputTypes";

export const ISINFields: FormFieldProps[] = [
  {
    type: "autoComplete",
    label: "Modelace obecného vs konkrétního instrumentu",
    name: "modelationType",
    optionsKey: "modelationTypeOptions",
  },
  {
    type: "autoComplete",
    label: "Název fondu / ISIN",
    name: "product",
    optionsKey: "securitiesOptions",
    placeHolder: "Zadejte",
    required: true,
  },
  {
    type: "autoComplete",
    label: "Měna fondu",
    name: "currency",
    placeHolder: "Doplněna automaticky",
    optionsKey: "currencyOptions",
    disabled: true,
  },
];
