import { currencyFormatter } from "src/utils/functions/helpers";
import { ServiceDataType, ValuationData } from "src/utils/types/outputTypes";

export const instrumentsLabels = {
  name: "Název služby",
  portfolioCurrency: "Referenční měna portfolia",
  totalDeposits: "Vklady celkem",
  withdrawals: "Vybráno celkem",
  governmentContributions: "Připsané státní příspěvky celkem",
};

export const getInstrumentsData = (
  portfolioData: ValuationData,
  data: ServiceDataType
) => {
  if (data?.summary) {
    return {
      name: data.serviceId,
      portfolioCurrency: portfolioData.portfolio.currency,
      totalDeposits: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(
        data.summary.clientDeposits +
          data.summary.employerContributions +
          data.summary.governmentContributions +
          data.summary.interests
      ),
      withdrawals: currencyFormatter(portfolioData?.portfolio?.currency).format(
        data.summary.withdrawals
      ),
      governmentContributions: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(data.summary.governmentContributions),
    };
  }
};
