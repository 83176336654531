import { FormFieldProps } from "src/utils/types/inputTypes";

export const serviceDefinitionFields: FormFieldProps[] = [
  {
    type: "autoComplete",
    label: "Typ produktu",
    name: "type",
    disabled: true,
    optionsKey: "productTypeOptions",
  },
  {
    type: "autoComplete",
    label: "Druh portfolio strategie",
    name: "PSType",
    optionsKey: "portfolioStrategyType",
    required: true,
  },
  {
    type: "autoComplete",
    label: "Název obchodního partnera",
    name: "invBusPartnerIn",
    optionsKey: "invBusPartnerOptions",
    placeHolder: "Zadejte",
  },
  {
    type: "autoComplete",
    label: "Název služby",
    name: "serviceIn",
    optionsKey: "servicesAndContractsOptions",
    placeHolder: "Zadejte",
    required: true,
  },
];
