import { Box, Stack, Button, Typography, Chip } from "@mui/material";
import { ChartIcon } from "src/components/icons";
import { useInputContext } from "src/contexts/InputContext";

import { defaultValues as SecurityFormDefaultValues } from "../../form/SecurityForm/defaultValues";
import { defaultValues as StrategyFormDefaultValues } from "../../form/PortfolioStrategyForm/defaultValues";
import { defaultValues as DPSDefaultValues } from "../../form/DPSForm/defaultValues";
import { defaultValues as BuildingSavingsDefaultValues } from "../../form/BuildingSavingsForm/defaultValues";
import { defaultValues as TransformedFundDefaultValues } from "../../form/TransformedFundForm/defaultValues";
import { defaultValues as CashAccountDefaultValues } from "../../form/CashAccountForm/defaultValues";
import { advisoryProductGroups } from "src/utils/enums/advisoryProductGroups";

export interface FormHeaderProps {
  currentTab: number;
  setCurrentTab: (currentTab: number) => void;
}
export const FormHeader = () => {
  const { inputItems, formData, setFormData } = useInputContext();

  const formEnum = {
    security: SecurityFormDefaultValues,
    "portfolio-strategy": StrategyFormDefaultValues,
    dps: DPSDefaultValues,
    "building-savings": BuildingSavingsDefaultValues,
    "transformed-fund": TransformedFundDefaultValues,
    "cash-account": CashAccountDefaultValues,
  };

  return (
    <Box sx={{ width: "100%", marginBottom: "32px" }}>
      <Box sx={styles.wrapper}>
        <ChartIcon />
        <Stack>
          <Typography variant="h6" marginBottom={2}>
            {formData?.title
              ? formData.title
              : advisoryProductGroups.find(
                  (obj) => obj.value === formData?.type?.value
                )?.label}
          </Typography>

          <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
            {inputItems.map((item) => (
              <Chip
                key={item.id}
                onClick={() => setFormData(item)}
                variant={formData?.title === item.title ? "filled" : "outlined"}
                label={item.title}
                sx={{ width: "fit-content" }}
              />
            ))}
            {advisoryProductGroups.map(({ value, label }, i) => (
              <Chip
                key={value}
                onClick={() => {
                  setFormData(formEnum[value as keyof typeof formEnum]);
                }}
                variant={
                  formData?.type?.value === value && !formData.id
                    ? "filled"
                    : "outlined"
                }
                label={label}
                sx={{ width: "fit-content" }}
              />
            ))}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

const styles = {
  wrapper: {
    display: "grid",
    gridTemplateColumns: "24px auto",
    gap: "16px",
  },
  button: {
    minWidth: "unset",
    width: "fit-content",
    whiteSpace: "nowrap",
  },
};
