import { Stack } from "@mui/material";
import {
  FormFieldProps,
  OptionType,
  ValueType,
} from "../../../utils/types/inputTypes";
import { FormSectionWrapper } from "./FormSectionWrapper";
import SectionContent from "./SectionContent";
import { FieldValues, UseFormReturn } from "react-hook-form";

interface SectionProps {
  name: string;
  title: string;
  form: UseFormReturn<any, any, undefined>;
  options: { [key: string]: OptionType[] | undefined };
  fields: FormFieldProps[];
  defaultValues: any;
}

const RepeatableSection = ({
  name,
  title,
  form,
  options,
  fields,
  defaultValues,
}: SectionProps) => {
  const { getValues, setValue, watch } = form;
  const values = watch(name);

  const addSection = () => {
    setValue(name, [...values, defaultValues]);
  };

  const removeSection = (index: number) => {
    setValue(
      name,
      values.filter((_: any, i: number) => i !== index)
    );
  };

  const onChangeField = ({
    sectionIndex,
    field,
    newValue,
  }: {
    sectionIndex: number;
    field: string;
    newValue: ValueType;
  }) => {
    const newValues = values?.map((value: any, index: number) => {
      if (index === sectionIndex) {
        return { ...value, [field]: newValue };
      }
      return value;
    });
    setValue(name, newValues);
  };

  return (
    <Stack sx={{ width: "100%" }} gap={"32px"}>
      {values?.map((section: any, index: number) => {
        return (
          <FormSectionWrapper
            title={title}
            handleAddSection={addSection}
            handleRmSection={() => removeSection(index)}
            isLast={index === values.length - 1}
            isAlone={values.length === 1}
            key={`${name}-section-${index}`}
            multiply
          >
            <SectionContent
              form={form}
              options={options}
              fields={fields}
              onChangeField={(fieldName: string, newValue: any) =>
                onChangeField({
                  sectionIndex: index,
                  field: fieldName,
                  newValue,
                })
              }
              fieldValues={values[index]}
            />
          </FormSectionWrapper>
        );
      })}
    </Stack>
  );
};

export default RepeatableSection;
