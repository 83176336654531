// src/components/AuthTokenDialog.tsx
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  TextField,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Stack,
  DialogTitle,
} from "@mui/material";
import { useInputContext } from "src/contexts";

export const SaveInputDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { inputItems } = useInputContext();
  const currentDate = new Date();
  const currentDateCode = dayjs(currentDate).format("DD_MM_YY_HH_mm");
  const currentDateFormatted = dayjs(currentDate).format("DD.MM.YY HH:mm");
  const [name, setName] = useState(currentDateFormatted);

  const savedInputs = JSON.parse(localStorage.getItem("savedInputs") || "{}");
  const handleSaveToken = () => {
    localStorage.setItem(
      "savedInputs",
      JSON.stringify({
        ...savedInputs,
        [name || currentDateFormatted]: {
          name: name,
          key: currentDateCode,
          savedItems: inputItems,
        },
      })
    );
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setName("");
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Uložit inputy do localStorage</DialogTitle>

      <DialogContent sx={{ width: "500px" }}>
        <Stack gap="12px">
          <Typography variant="body1">
            Soubor se stejným názvem bude přepsán
          </Typography>
          <Stack gap="24px">
            <TextField
              autoFocus
              margin="dense"
              label="Název souboru inputů"
              type="text"
              fullWidth
              multiline
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <Typography variant="body1" fontWeight={600}>
              Položky k uložení:
            </Typography>
            <Stack direction="row" flexWrap={"wrap"} gap="12px">
              {inputItems.map((item) => (
                <Chip key={item.id} label={item.title} variant={"outlined"} />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSaveToken} color="primary">
          Uložit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
