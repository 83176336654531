import React from "react";
import dayjs from "dayjs";
import { useState } from "react";

import {
  Box,
  Stack,
  IconButton,
  Tabs,
  Tab,
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";

import { HistoryChart, FutureChart, CombiChart } from "src/components/charts";
import { ChartIcon, TableIcon } from "src/components/icons";
import {
  TimeGranularityPicker,
  FormDialog,
  LoadingCircle,
  SavedFundsList,
  FormTypeDialog,
  ContentWrapper,
  FilterButton,
} from "src/components";
import TablePage from "./TablePage";
import { GranularityPicker } from "src/components/buttons/TimeGranularityPicker/GranularityPicker";

const AlertComponent = ({ title, description }: any) => {
  return (
    <Alert severity="error">
      <AlertTitle>{title}</AlertTitle>
      {description}
    </Alert>
  );
};

const MainPage = ({
  historyData,
  historyPerformanceData,
  historyPerformanceDataXIRR,
  timeRange,
  setTimeRange,
  futureData,
  combinationData,
  isFetching,
}: any) => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const [currentDataset, setCurrentDataset] = useState<
    ("portfolioData" | "serviceData" | "productsData")[]
  >(["portfolioData"]);
  const [page, setPage] = useState(0);

  return (
    <Box
      sx={{
        my: 10,
        display: "flex",
        justifyContent: open ? "end" : "center",
      }}
    >
      <FormDialog open={open} setOpen={setOpen} />

      <Box
        sx={{
          maxWidth: open ? "98vw" : "90vw",
          width: open ? "1800px" : "1200px",
          paddingRight: open ? "620px" : 0,
        }}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Historický vývoj" {...a11yProps(0)} />
            <Tab label="Budoucí vývoj" {...a11yProps(1)} />
            <Tab label="Historický + budoucí vývoj" {...a11yProps(2)} />
          </Tabs>
          <FormTypeDialog openForm={setOpen} />{" "}
        </Stack>
        <SavedFundsList setOpen={setOpen} />
        <Stack direction={"row"} justifyContent={"end"} gap={"12px"}>
          <ContentWrapper value={page} index={1}>
            <IconButton onClick={() => setPage(0)}>
              <ChartIcon />
            </IconButton>
          </ContentWrapper>
        </Stack>
        <ContentWrapper value={page} index={0}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ marginBottom: "24px" }}
          >
            <FilterButton
              currentDataset={currentDataset}
              setCurrentDataset={setCurrentDataset}
            />
            <Stack direction={"row"} gap={"12px"} justifyContent={"end"}>
              <GranularityPicker
                timeRange={timeRange}
                setTimeRange={setTimeRange}
              />

              <ContentWrapper value={value} index={0}>
                <TimeGranularityPicker
                  timeRange={timeRange}
                  setTimeRange={setTimeRange}
                  modelatedData={{
                    valuation: historyData.raw,
                    performance: historyPerformanceData.raw,
                  }}
                  modelationType={"history"}
                />
              </ContentWrapper>
              <ContentWrapper value={value} index={1}>
                <TimeGranularityPicker
                  timeRange={timeRange}
                  setTimeRange={setTimeRange}
                  modelatedData={futureData.raw}
                  modelationType={"future"}
                />
              </ContentWrapper>
              <ContentWrapper value={value} index={2}>
                <TimeGranularityPicker
                  timeRange={timeRange}
                  setTimeRange={setTimeRange}
                  modelatedData={combinationData.raw}
                  modelationType={"combination"}
                />
              </ContentWrapper>
              <IconButton onClick={() => setPage(1)}>
                <TableIcon />
              </IconButton>
            </Stack>
          </Stack>
        </ContentWrapper>

        <>
          <ContentWrapper value={value} index={0}>
            {isFetching.history && <LoadingCircle />}

            {historyData.error ? (
              <AlertComponent
                title={historyData.error.split("details:")[0]}
                description={historyData.error.split("details:")[1]}
              />
            ) : historyData.data ? (
              <>
                <ContentWrapper value={page} index={0}>
                  <HistoryChart
                    chartData={historyData.data}
                    performanceData={historyPerformanceData}
                    tableData={historyData.raw}
                    loading={isFetching.history}
                    open={open}
                    currentDataset={currentDataset}
                  />
                </ContentWrapper>
                <ContentWrapper value={page} index={1}>
                  <TablePage
                    data={historyData?.raw}
                    performanceData={historyPerformanceData?.raw}
                    xirrData={historyPerformanceDataXIRR?.raw}
                    setOpen={setOpen}
                  />
                </ContentWrapper>
              </>
            ) : (
              <Box>
                Začněte modelovat portfolia přidáním instrumentů vpravo nahoře.
              </Box>
            )}
          </ContentWrapper>

          <ContentWrapper value={value} index={1}>
            {isFetching.future && <LoadingCircle />}
            {futureData.error ? (
              <AlertComponent
                title={futureData.error.split("details:")[0]}
                description={futureData.error.split("details:")[1]}
              />
            ) : futureData.data ? (
              <>
                <ContentWrapper value={page} index={0}>
                  <FutureChart
                    data={futureData.data}
                    tableData={futureData.raw}
                    loading={isFetching.future}
                    open={open}
                    currentDataset={currentDataset}
                  />
                </ContentWrapper>
                <ContentWrapper value={page} index={1}>
                  <TablePage
                    data={futureData.raw}
                    setOpen={setOpen}
                    futurePage={true}
                  />
                </ContentWrapper>
              </>
            ) : (
              <Box>
                Začněte modelovat portfolia přidáním instrumentů vpravo nahoře.
              </Box>
            )}
          </ContentWrapper>

          <ContentWrapper value={value} index={2}>
            {isFetching.combination && <LoadingCircle />}
            {combinationData.error ? (
              <AlertComponent
                title={combinationData.error.split("details:")[0]}
                description={combinationData.error.split("details:")[1]}
              />
            ) : combinationData.data ? (
              <>
                <ContentWrapper value={page} index={0}>
                  <CombiChart
                    data={combinationData.data}
                    tableData={combinationData.raw}
                    loading={isFetching.combination}
                    open={open}
                    currentDataset={currentDataset}
                  />
                </ContentWrapper>
                <ContentWrapper value={page} index={1}>
                  <TablePage
                    data={combinationData.raw}
                    setOpen={setOpen}
                    futurePage={true}
                  />
                </ContentWrapper>
              </>
            ) : (
              <Box>
                Začněte modelovat portfolia přidáním instrumentů vpravo nahoře.
              </Box>
            )}
          </ContentWrapper>
        </>
      </Box>
    </Box>
  );
};

export default MainPage;
