import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

interface Props {
  label: string;
  name: string;
  value: Dayjs | null;
  onChange: (name: string, newValue: Dayjs | null) => void;
  disabled?: boolean;
  defaultValue?: string;
  required?: boolean;
}

export const ControlledDatePick = ({
  value,
  name,
  label,
  disabled,
  onChange,
}: Props) => {
  return (
    <DatePicker
      label={label}
      disabled={disabled}
      value={value ? dayjs(value) : null}
      onChange={(newValue) => onChange(name, newValue)}
      slotProps={{
        textField: { variant: "standard", InputLabelProps: { shrink: true } },
      }}
      minDate={dayjs("1986-01-31")}
    />
  );
};
