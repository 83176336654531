// src/components/AuthTokenDialog.tsx
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAuth } from "src/contexts/AuthContext";
import { Typography } from "@mui/material";

export const AuthTokenDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { authToken, setAuthToken } = useAuth();
  const [token, setToken] = useState(authToken || "");

  const handleSaveToken = () => {
    setAuthToken(token);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent sx={{ width: "500px" }}>
        <Typography sx={{ marginBottom: 2 }}>
          {'Vložte pouze kód, bez "Bearer"'}
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          label="Autorizační token"
          type="text"
          fullWidth
          multiline
          variant="outlined"
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSaveToken} color="primary">
          Uložit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
