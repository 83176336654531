import { BuildingSavingsFormProps } from "src/utils/types/inputTypes";

export const cashFlowDefault = {
  cashFlowCurrency: { value: "", label: "" },
  amount: null,
  from: null,
  to: null,
  frequency: { value: "", label: "" },
};

export const withdrawalsDefault = {
  amount: null,
  from: null,
  to: null,
  frequency: { value: "", label: "" },
};

export const savingsGoalDefault = {
  currency: { value: "", label: "" },
  amount: null,
};

export const defaultValues: BuildingSavingsFormProps = {
  type: { value: "building-savings", label: "Stavební spoření" },
  id: null,
  invBusPartnerIn: { value: "", label: "" },
  serviceIn: { value: 0, label: "Obecná služba" },
  modelationType: { value: "", label: "" },
  product: {
    value: "",
    label: "",
    data: {
      currency: "",
      serviceIn: "",
      invBusPartnerIn: "",
    },
  },
  portfolioCurrency: { value: "", label: "" },
  cashFlow: [cashFlowDefault],
  savingsGoal: { currency: { value: "", label: "" }, amount: null },
  governmentContribution: { value: "", label: "" },
  savingDuration: null,
  interestRate: null,
  depositInterestRate: null,
  maxContributionValue: null,
  earlyTerminationDate: null,
};
