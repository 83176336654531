/* eslint-disable no-nested-ternary */
import { useQuery } from "react-query";
import axios from "axios";

import QueryString from "qs";
import { useAuth } from "src/contexts/AuthContext";
import { useConfig } from "src/contexts/ConfigContext";

export const useGetPortfolioStrategies = ({
  serviceIn,
  invBusPartnerIn,
  portfolioStrategyTypeIn,
}: {
  serviceIn?: string | number;
  invBusPartnerIn?: string | number;
  portfolioStrategyTypeIn?: string | number;
}) => {
  const { authToken } = useAuth();
  const { config } = useConfig();

  const getOptionsFromFetch = (data: any) => {
    return data.rows.map((obj: any) => ({
      value: obj.id,
      label: `${obj.portfolioStrategyType.name.cs || obj.id}; ${obj.id}; ${obj
        .serviceIdentification?.service?.name?.cs}; ${obj.serviceIdentification
        ?.service?.invBusPartner?.name?.cs}`,
      data: {
        invBusPartnerIn: obj.serviceIdentification?.service?.invBusPartner?.id,
        serviceIn: obj.serviceIdentification?.service?.id,
        currency: obj.currency.code,
        strategyType: obj.portfolioStrategyType.id,
      },
    }));
  };

  const fetch = async () => {
    const res = await axios.get(
      `${config.catalogUrl}${config.version}/PortfolioStrategy`,
      {
        params: {
          pageSize: 200,
          serviceIn: serviceIn || null,
          invBusPartnerIn: invBusPartnerIn || null,
          portfolioStrategyTypeIn: portfolioStrategyTypeIn || null,
        },
        paramsSerializer: {
          serialize: (params) => {
            return QueryString.stringify(params, { skipNulls: true });
          },
        },

        headers: {
          accept: "application/json",
          contentType: "application/json",
          correlationid: "382d3541-1c07-47d9-8426-d04152e82261",
          ofsauthorization: `Bearer ${authToken}`,
          userAgent: "vscode-restclient",
        },
      }
    );
    const transformedData = getOptionsFromFetch(res.data);
    return transformedData;
  };

  return useQuery(
    `getPortfolioStrategies-${invBusPartnerIn}-${serviceIn}-${portfolioStrategyTypeIn}`,
    fetch,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
      enabled: !!authToken && !!config.catalogUrl,
    }
  );
};
