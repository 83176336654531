import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { ChevronIcon } from "../../icons";
import { MenuList, MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import { OptionType } from "src/utils/types/inputTypes";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const GranularityPicker = ({ timeRange, setTimeRange }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const granularityOptions = [
    {
      value: 1,
      label: "Den",
    },
    {
      value: 2,
      label: "3 Dny",
    },
    {
      value: 3,
      label: "5 Dnů",
    },
    {
      value: 4,
      label: "Týden",
    },
    {
      value: 5,
      label: "2 Týdny",
    },
    {
      value: 6,
      label: "1 Měsíc",
    },
    {
      value: 7,
      label: "3 Měsíce",
    },
    {
      value: 8,
      label: "6 Měsíců",
    },
    {
      value: 9,
      label: "Rok",
    },
  ];

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(null);
    } else if (event.key === "Escape") {
      setAnchorEl(null);
    }
  }

  return (
    <>
      <Button
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
        startIcon={<ChevronIcon up={!open} />}
      >
        {`Granularita: ${granularityOptions.find(
          (option) => option.value === (timeRange.granularity || 9)
        )?.label}`}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList
          autoFocusItem={open}
          id="composition-menu"
          aria-labelledby="composition-button"
          onKeyDown={handleListKeyDown}
        >
          {granularityOptions.map((option: OptionType) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                setTimeRange({
                  ...timeRange,
                  granularity: option.value,
                });
                handleClose();
              }}
            >
              <ListItemIcon>
                {timeRange.granularity === option.value ? (
                  <CheckCircleOutlineIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )}
              </ListItemIcon>
              <ListItemText> {option.label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
};
