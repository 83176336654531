import * as React from "react";
import Popover from "@mui/material/Popover";
import { Button, Box } from "@mui/material";
import { ChevronIcon } from "../../icons";
import { useInputContext } from "src/contexts/InputContext";
import {
  ClickAwayListener,
  MenuList,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { currencyFormatter } from "src/utils/functions/helpers";
import { useGetCurrency } from "src/queries";
import { OptionType } from "src/utils/types/inputTypes";

export const PortfolioCurrencyPicker = ({
  valuation,
  loading,
}: {
  valuation: number;
  loading: boolean;
}) => {
  const { portfolioData, setPortfolioData } = useInputContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { data: currencyOptions } = useGetCurrency();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(null);
    } else if (event.key === "Escape") {
      setAnchorEl(null);
    }
  }

  return (
    <Box sx={{ justifySelf: "center" }}>
      <Button
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
        endIcon={<ChevronIcon up={!open} />}
        sx={{ fontSize: "1rem", padding: 0 }}
      >
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          currencyFormatter(portfolioData.currency).format(valuation)
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}
          >
            {currencyOptions?.map((currency: OptionType) => (
              <MenuItem
                key={currency.value}
                onClick={() => {
                  setPortfolioData({
                    ...portfolioData,
                    currency: String(currency.value),
                  });
                  handleClose();
                }}
              >
                {currency.label}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Popover>
    </Box>
  );
};
