import { FormFieldProps } from "src/utils/types/inputTypes";

export const savingsGoalFields: FormFieldProps[] = [
  {
    type: "autoComplete",
    label: "Měna cílové částky",
    name: "savingsGoalCurrency",
    optionsKey: "currencyOptions",
    disabled: true,
  },
  {
    type: "textField",
    label: "Výše cílové částky",
    name: "amount",
    fieldType: "number",
    disabled: true,
  },
];
