import dayjs from "dayjs";
import { currencyFormatter } from "src/utils/functions/helpers";
import { ValuationData } from "src/utils/types/outputTypes";

export const portfolioLabels = {
  from: "Počátek modelace (vznik portfolia)",
  to: "Konec modelace",
  currency: "Referenční měna portfolia",
  totalDeposits: "Vklady celkem",
  investments: "Čisté výkady (nákupy) celkem",
  withdrawals: "Výběry celkem",
  valuation: "Hodnota portfolia na konci modelace",
  fees: "Poplatky",
};

export const getPortfolioData = (portfolioData: ValuationData) => {
  if (portfolioData) {
    return {
      from: dayjs(portfolioData.from).format("DD. MM. YYYY"),
      to: dayjs(portfolioData.to).format("DD. MM. YYYY"),
      currency: portfolioData.portfolio.currency,
      totalDeposits: currencyFormatter(portfolioData.portfolio.currency).format(
        (portfolioData?.portfolio?.summary?.clientDeposits || 0) +
          (portfolioData?.portfolio?.summary?.employerContributions || 0) +
          (portfolioData?.portfolio?.summary?.governmentContributions || 0) +
          (portfolioData?.portfolio?.summary?.interests || 0)
      ),
      investments: currencyFormatter(portfolioData.portfolio.currency).format(
        portfolioData?.portfolio?.summary?.investments || 0
      ),
      withdrawals: currencyFormatter(portfolioData.portfolio.currency).format(
        portfolioData?.portfolio?.summary?.withdrawals || 0
      ),
      valuation: portfolioData?.portfolio?.summary?.value
        ? currencyFormatter(portfolioData.portfolio.currency).format(
            portfolioData?.portfolio?.summary?.value || 0
          )
        : "- -",
      fees: currencyFormatter(portfolioData.portfolio.currency).format(
        (portfolioData.portfolio.summary?.oneOffPurchaseFees || 0) +
          (portfolioData.portfolio.summary?.redemptionFees || 0) +
          (portfolioData.portfolio.summary?.expressPurchaseFees || 0) +
          (portfolioData.portfolio.summary?.regularPurchaseFees || 0) +
          (portfolioData.portfolio.summary?.standardPurchaseFees || 0)
      ),
    };
  }
};
