/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

import { Box, Stack, Chip } from "@mui/material";
import {
  ServicesTable,
  InstrumentsTable,
  InstrumentsFeesTable,
  DPSTable,
  BuildingSavingsTable,
  CashAccountTable,
  PerformanceTable,
} from "src/components/tables";
import { PortfolioSummary } from "src/components/tables/PortfolioSummary";
import { advisoryProductGroups } from "src/utils/enums/advisoryProductGroups";
import { TransformedFundTable } from "src/components/tables/TransformedFundTable";
import { ScenariosPicker } from "src/components";

const TablePage = ({
  data,
  performanceData,
  xirrData,
  setOpen,
  futurePage,
}: any) => {
  const [scenario, setScenario] = useState("neutral");
  const [filteredType, setFilteredType] = useState<string>();
  const productTypeOptions = advisoryProductGroups;

  const tableData = futurePage
    ? {
        ...data.forecast[scenario],
        portfolio: {
          ...data.forecast[scenario].portfolio,
          currency: data.currency,
        },
      }
    : data;

  return (
    <Stack gap="24px">
      {data ? (
        <>
          {futurePage && (
            <ScenariosPicker
              pickedScenario={scenario}
              setPickedScenario={setScenario}
            />
          )}
          <PortfolioSummary data={tableData} />
          <Stack
            flexWrap={"wrap"}
            direction={"row"}
            gap="12px"
            marginBottom={2}
          >
            <Chip
              key={"all"}
              label={"Vše"}
              onClick={() => setFilteredType(undefined)}
              variant={!filteredType ? "filled" : "outlined"}
            />
            {productTypeOptions?.map((option) => (
              <Chip
                key={option.value}
                label={option.label}
                onClick={() => {
                  setFilteredType(option.value);
                }}
                variant={filteredType === option.value ? "filled" : "outlined"}
              />
            ))}
          </Stack>
          {data ? (
            <>
              {!filteredType && (
                <>
                  <ServicesTable data={tableData} filteredType={filteredType} />
                  <InstrumentsTable
                    data={tableData}
                    filteredType={filteredType}
                  />
                </>
              )}
              {["security", "portfolio-strategy"].includes(
                filteredType || ""
              ) && (
                <ServicesTable data={tableData} filteredType={filteredType} />
              )}
              {filteredType === "dps" && (
                <DPSTable data={tableData} filteredType={filteredType} />
              )}
              {filteredType === "transformed-fund" && (
                <TransformedFundTable
                  data={tableData}
                  filteredType={filteredType}
                />
              )}
              {filteredType === "building-savings" && (
                <BuildingSavingsTable
                  data={tableData}
                  filteredType={filteredType}
                />
              )}
              {filteredType === "cash-account" && (
                <CashAccountTable
                  data={tableData}
                  filteredType={filteredType}
                />
              )}
              <InstrumentsFeesTable
                data={tableData}
                filteredType={filteredType}
              />
              {!futurePage && xirrData && (
                <PerformanceTable
                  data={performanceData}
                  xirrData={xirrData}
                  filteredType={filteredType}
                />
              )}
            </>
          ) : (
            "Žádné výsledky"
          )}
        </>
      ) : (
        <Box>
          Začněte modelovat portfolia přidáním instrumentů vpravo nahoře.
        </Box>
      )}
    </Stack>
  );
};

export default TablePage;
