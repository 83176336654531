import { FormTypes } from "src/utils/types/inputTypes";
import { FutureData, SeriesData, Valuation } from "../../types/outputTypes";
import { getNameFromInput, getServiceFromInput } from "../helpers";
import { chartColors } from "src/utils/enums/chartColors";

export const transformCombiData = ({
  data,
  input,
}: {
  data: FutureData;
  input: FormTypes[];
}) => {
  const firstValue = { x: new Date(data.from), y: 0 };
  const lastValuationObj =
    data.history.portfolio.valuation[
      data.history.portfolio.valuation.length - 1
    ];
  const lastHistoryValue = {
    x: new Date(lastValuationObj?.date),
    y: lastValuationObj?.value,
  };

  const getValuation = (dataArr: Valuation) => {
    const data = dataArr.map((obj) => ({
      x: new Date(obj.date),
      y: obj.value,
    }));
    return data;
  };

  const getCashFlow = (dataArr: Valuation) => {
    const data = dataArr.map((obj) => ({
      x: new Date(obj.date),
      y: obj.depositAcc,
    }));
    return data;
  };

  const portfolioData: { [key: string]: { [key: string]: SeriesData } } = {
    portfolio: {},
  };
  const serviceData: { [key: string]: { [key: string]: SeriesData } } = {};
  const productsData: { [key: string]: { [key: string]: SeriesData } } = {};

  const scenarioEnum = {
    negative: { label: "Negativní scénář", color: "#f33b12" },
    neutral: { label: "Neutrální scénář", color: "#f9b429" },
    positive: { label: "Pozitivní scénář", color: "#00a7b8" },
  };

  const scenarios: ("negative" | "neutral" | "positive")[] = [
    "negative",
    "neutral",
    "positive",
  ];
  const palette = chartColors;
  let paletteIndex = 0;

  scenarios.forEach((key) => {
    portfolioData.portfolio[key] = {
      id: `portfolio_${key}`,
      label: `${scenarioEnum[key as keyof typeof scenarioEnum].label}`,
      data: [
        lastHistoryValue,
        ...getValuation(
          data.forecast[key as keyof typeof data.forecast].portfolio.valuation
        ),
      ],
      stack: "",
      borderColor: scenarioEnum[key as keyof typeof scenarioEnum].color,
      order: 2,
      yAxisID: "valuation",
      setID: "portfolio",
      setLabel: "Portfolio",
      hidden: false,
      borderWidth: 2,
    };

    data.forecast[
      key as keyof typeof data.forecast
    ].portfolio.byServices.forEach((service, serviceIndex) => {
      const serviceKey = `service_${service.serviceId}`;
      const serviceLabel = getServiceFromInput({
        serviceId: service.serviceId,
        input,
      });

      serviceData[serviceKey] = serviceData[serviceKey] || {};
      const lastServiceIndex =
        data.history.portfolio.byServices[serviceIndex].valuation.length;
      const lastHistoryServiceObj =
        data.history.portfolio.byServices[serviceIndex].valuation[
          lastServiceIndex - 1
        ];
      const lastHistoryServiceValue = {
        x: new Date(lastHistoryServiceObj?.date),
        y: lastHistoryServiceObj?.value,
      };

      serviceData[serviceKey][key] = {
        id: `${serviceKey}_${key}`,
        label: `${scenarioEnum[key as keyof typeof scenarioEnum].label}`,
        data: [lastHistoryServiceValue, ...getValuation(service.valuation)],
        stack: "",
        borderColor: scenarioEnum[key as keyof typeof scenarioEnum].color,
        order: 2,
        yAxisID: "valuation",
        setID: serviceKey,
        setLabel: `${serviceLabel}`,
        hidden: false,
        borderWidth: 2,
      };

      service.byProducts.forEach((product, productIndex) => {
        const productKey = `product_${service.serviceId}_${product.productId}`;
        const productLabel = getNameFromInput({
          productId: product.productId,
          serviceId: service.serviceId,
          input,
        });

        productsData[productKey] = productsData[productKey] || {};
        const lastProductIndex =
          data.history.portfolio.byServices[serviceIndex].byProducts[
            productIndex
          ].valuation.length;
        const lastHistoryProductObj =
          data.history.portfolio.byServices[serviceIndex].byProducts[
            productIndex
          ].valuation[lastProductIndex - 1];
        const lastHistoryProductValue = {
          x: new Date(lastHistoryProductObj?.date),
          y: lastHistoryProductObj?.value,
        };

        productsData[productKey][key] = {
          id: `${productKey}_${key}`,
          label: `${scenarioEnum[key as keyof typeof scenarioEnum].label}`,
          data: [lastHistoryProductValue, ...getValuation(service.valuation)],
          stack: "",
          borderColor: scenarioEnum[key as keyof typeof scenarioEnum].color,
          order: 3,
          yAxisID: "valuation",
          setID: productKey,
          hidden: false,
          setLabel: `${productLabel}`,
          borderWidth: 2,
        };
      });
    });
  });

  portfolioData.portfolio.valuation = {
    id: "portfolio_valuation",
    label: "Hodnota",
    data: [firstValue, ...getValuation(data.history.portfolio.valuation)],
    stack: "",
    order: 2,
    yAxisID: "valuation",
    setID: "portfolio",
    setLabel: "Portfolio",
    hidden: false,
    borderColor: palette[paletteIndex],
    borderWidth: 2,
  };

  portfolioData.portfolio.cashFlow = {
    id: "portfolio_cashFlow",
    label: "Cashflow",
    data: [
      ...getCashFlow(data.history.portfolio.valuation),
      ...getCashFlow(data.forecast.neutral.portfolio.valuation),
    ],
    stack: "",
    borderColor: "#5f6c6e",
    order: 2,
    yAxisID: "valuation",
    setID: "portfolio",
    setLabel: "Portfolio",
    hidden: false,
    borderWidth: 2,
    borderDash: [5, 2],
    stepped: true,
  };

  data.history.portfolio.byServices.forEach((service, serviceIndex) => {
    const serviceKey = `service_${service.serviceId}`;
    const serviceLabel = getServiceFromInput({
      serviceId: service.serviceId,
      input,
    });
    serviceData[serviceKey] = serviceData[serviceKey] || {};
    paletteIndex = (paletteIndex + 1) % palette.length;

    serviceData[serviceKey].valuation = {
      id: `service_${service.serviceId}_valuation`,
      label: "Hodnota",
      data: [firstValue, ...getValuation(service.valuation)],
      stack: "",

      order: 2,
      yAxisID: "valuation",
      setID: serviceKey,
      setLabel: `${serviceLabel}`,
      hidden: false,
      borderColor: palette[paletteIndex],
      borderWidth: 2,
    };

    serviceData[serviceKey].cashFlow = {
      id: `service_${service.serviceId}_cashFlow`,
      label: "CashFlow",
      data: [
        ...getCashFlow(service.valuation),
        ...getCashFlow(
          data.forecast.neutral.portfolio.byServices[serviceIndex].valuation
        ),
      ],
      stack: "",
      order: 2,
      yAxisID: "valuation",
      setID: serviceKey,
      setLabel: `${serviceLabel}`,
      hidden: false,
      borderColor: palette[paletteIndex],
      borderWidth: 2,
      borderDash: [5, 2],
      stepped: true,
    };

    service.byProducts.forEach((product, productIndex) => {
      const productKey = `product_${service.serviceId}_${product.productId}`;
      const productLabel = getNameFromInput({
        productId: product.productId,
        serviceId: service.serviceId,
        input,
      });
      paletteIndex = (paletteIndex + 1) % palette.length;

      productsData[productKey].valuation = {
        id: `${productKey}_valuation`,
        label: "Hodnota",
        data: [firstValue, ...getValuation(service.valuation)],
        stack: "",
        order: 3,
        yAxisID: "valuation",
        setID: productKey,
        hidden: false,
        setLabel: `${productLabel}`,
        borderColor: palette[paletteIndex],
        borderWidth: 2,
      };

      productsData[productKey].cashFlow = {
        id: `${productKey}_cashFlow`,
        label: "CashFlow",
        data: [
          ...getCashFlow(product.valuation),
          ...getCashFlow(
            data.forecast.neutral.portfolio.byServices[serviceIndex].byProducts[
              productIndex
            ].valuation
          ),
        ],
        stack: "",
        order: 3,
        yAxisID: "valuation",
        setID: productKey,
        hidden: false,
        setLabel: `${productLabel}`,
        borderColor: palette[paletteIndex],
        borderWidth: 2,
        borderDash: [5, 2],
        stepped: true,
      };
    });
  });

  return {
    minMax_X: { min: new Date(data.from), max: new Date(data.to) },
    data: {
      portfolioData,
      serviceData,
      productsData,
    },
  };
};
