import { currencyFormatter } from "src/utils/functions/helpers";
import { ServiceDataType, ValuationData } from "src/utils/types/outputTypes";

export const servicesLabels = {
  name: "Název služby",
  currency: "Referenční měna portfolia",
  totalDeposits: "Vklady celkem",
  investments: "Čisté vklady (nákupy) celkem",
  withdrawals: "Výběry celkem",
};

export const getServicesData = (
  portfolioData: ValuationData,
  data: ServiceDataType
) => {
  if (data?.summary) {
    return {
      name: data.serviceId,
      currency: portfolioData.portfolio.currency,
      totalDeposits: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(
        data.summary.clientDeposits +
          data.summary.employerContributions +
          data.summary.governmentContributions +
          data.summary.interests
      ),
      investments: currencyFormatter(portfolioData?.portfolio?.currency).format(
        data.summary.investments
      ),
      withdrawals: currencyFormatter(portfolioData?.portfolio?.currency).format(
        data.summary.withdrawals
      ),
    };
  }
};
