import { Box } from "@mui/material";
import { SecurityForm } from "../SecurityForm";
import { FormDialogWrapper } from "../components";
import { FormHeader } from "../components/FormHeader";
import { PortfolioStrategyForm } from "../PortfolioStrategyForm";
import { useInputContext } from "src/contexts/InputContext";
import { DPSForm } from "../DPSForm";
import { BuildingSavingsForm } from "../BuildingSavingsForm";
import { CashAccountForm } from "../CashAccountForm";
import { TransformedFundForm } from "../TransformedFundForm";

export const FormDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { formData } = useInputContext();

  return (
    <FormDialogWrapper open={open} setOpen={setOpen}>
      <Box sx={{ padding: "24px" }}>
        <FormHeader />
        {formData?.type?.value === "security" && (
          <SecurityForm
            key={formData?.id || formData?.type?.value}
            setOpen={setOpen}
          />
        )}
        {formData?.type?.value === "portfolio-strategy" && (
          <PortfolioStrategyForm
            key={formData?.id || formData?.type?.value}
            setOpen={setOpen}
          />
        )}
        {formData?.type?.value === "dps" && (
          <DPSForm
            key={formData?.id || formData?.type?.value}
            setOpen={setOpen}
          />
        )}
        {formData?.type?.value === "building-savings" && (
          <BuildingSavingsForm
            key={formData?.id || formData?.type?.value}
            setOpen={setOpen}
          />
        )}
        {formData?.type?.value === "cash-account" && (
          <CashAccountForm
            key={formData?.id || formData?.type?.value}
            setOpen={setOpen}
          />
        )}
        {formData?.type?.value === "transformed-fund" && (
          <TransformedFundForm
            key={formData?.id || formData?.type?.value}
            setOpen={setOpen}
          />
        )}
      </Box>
    </FormDialogWrapper>
  );
};
