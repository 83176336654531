import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { ChevronIcon } from "../../icons";
import { useInputContext } from "src/contexts/InputContext";
import {
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { OptionType } from "src/utils/types/inputTypes";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const HistoryDataPicker = ({
  pickedType,
  setPickedType,
  disabled,
}: {
  pickedType: any;
  setPickedType: any;
  disabled: boolean;
}) => {
  const { portfolioData, setPortfolioData } = useInputContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(null);
    } else if (event.key === "Escape") {
      setAnchorEl(null);
    }
  }

  const options = [
    {
      value: "valuation",
      label: "Hodnota portfolia",
    },
    {
      value: "absolute",
      label: "Celková absolutní výnosnost",
    },
    { value: "relative", label: "Celková relativní výnosnost v %" },
  ];

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
        endIcon={<ChevronIcon up={!open} />}
        disabled={disabled}
        sx={{ fontSize: "1rem", padding: 0 }}
      >
        {options.find((option) => pickedType === option.value)?.label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}
          >
            {options.map((option: OptionType) => (
              <MenuItem
                key={option.value}
                onClick={() => {
                  setPickedType(option.value);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  {pickedType === option.value ? (
                    <CheckCircleOutlineIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
                <ListItemText> {option.label}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Popover>
    </div>
  );
};
