import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Dialog,
} from "@mui/material";
import { useState } from "react";
import { useInputContext } from "src/contexts/InputContext";
import { defaultValues as SecurityFormDefaultValues } from "../../form/SecurityForm/defaultValues";
import { defaultValues as StrategyFormDefaultValues } from "../../form/PortfolioStrategyForm/defaultValues";
import { defaultValues as DPSDefaultValues } from "../../form/DPSForm/defaultValues";
import { defaultValues as BuildingSavingsDefaultValues } from "../../form/BuildingSavingsForm/defaultValues";
import { defaultValues as TransformedFundDefaultValues } from "../../form/TransformedFundForm/defaultValues";
import { defaultValues as CashAccountDefaultValues } from "../../form/CashAccountForm/defaultValues";
import AddIcon from "@mui/icons-material/Add";
import { advisoryProductGroups } from "src/utils/enums/advisoryProductGroups";

export const FormTypeDialog = ({
  openForm,
}: {
  openForm: (open: boolean) => void;
}) => {
  const [open, setOpen] = useState(false);
  const { setFormData } = useInputContext();
  const productTypeOptions = advisoryProductGroups;

  const formEnum = {
    security: SecurityFormDefaultValues,
    "portfolio-strategy": StrategyFormDefaultValues,
    dps: DPSDefaultValues,
    "building-savings": BuildingSavingsDefaultValues,
    "transformed-fund": TransformedFundDefaultValues,
    "cash-account": CashAccountDefaultValues,
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="text"
        sx={{
          borderRadius: "100px",
          backgroundColor: "#f9fafb !important",
          padding: "12px 24px",
          color: "#00abbd",
          boxShadow: 3,
          width: "fit-content",
        }}
        startIcon={<AddIcon />}
      >
        Přidat instrument
      </Button>
      {open && (
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={open}
          onClose={() => setOpen(false)}
        >
          <Box sx={{ padding: "24px" }}>
            <Typography sx={{ fontSize: "28px" }}>
              Vyberte typ instrumentu do modelace
            </Typography>
            <List>
              {productTypeOptions?.map(
                (option: { value: string; label: string }) => {
                  return (
                    <ListItem key={option.value} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          setFormData(
                            formEnum[option.value as keyof typeof formEnum]
                          );
                          openForm(true);
                          setOpen(false);
                        }}
                      >
                        <ListItemText primary={`${option.label}`} />
                      </ListItemButton>
                    </ListItem>
                  );
                }
              )}
            </List>
          </Box>
        </Dialog>
      )}
    </>
  );
};
