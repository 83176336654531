import dayjs from "dayjs";
import { formatTooltip } from ".";
import { TooltipItem } from "chart.js";
import {
  currencyFormatter,
  percentFormatter,
} from "src/utils/functions/helpers";

export const getChartOptions = ({
  portfolioCurrency,
  doubleY,
  currentDataType,
  showNavs,
  datasetLength,
}: {
  portfolioCurrency: string;
  doubleY?: boolean;
  currentDataType?: string;
  showNavs?: boolean;
  datasetLength?: number;
}) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
      },
      tooltip: {
        mode: "nearest" as const,
        intersect: false,
        position: "nearest" as const,
        callbacks: {
          title: (value: any) => {
            return dayjs(value[0].parsed.x).format("DD. MM. YYYY");
          },
          label: (context: TooltipItem<"line">) => {
            return formatTooltip({
              context,
              portfolioCurrency: portfolioCurrency,
            });
          },
        },
      },
    },
    ...((datasetLength || 0) > 80
      ? {
          elements: {
            point: {
              radius: 0,
              hitRadius: 10,
            },
          },
        }
      : {}),

    scales: {
      x: {
        type: "timeseries" as const,
        time: {
          tooltipFormat: "DD T",
        },
        ticks: {
          callback: (value: any) => {
            return dayjs(value).format("DD. MM. YYYY");
          },
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      ...(currentDataType === "relative"
        ? {
            relative: {
              type: "linear" as const,
              display: true,
              position: "left" as const,
              ticks: {
                callback: (value: any) => {
                  return percentFormatter().format(value);
                },
                autoSkip: true,
                maxTicksLimit: 10,
              },
            },
          }
        : {
            valuation: {
              type: "linear" as const,
              display: true,
              position: "left" as const,
              ticks: {
                callback: (value: any) => {
                  return currencyFormatter(portfolioCurrency).format(value);
                },
                autoSkip: true,
                maxTicksLimit: 10,
              },
            },
          }),
      ...(showNavs
        ? {
            navs: {
              type: "linear" as const,
              display: true,
              position: "right" as const,
              ticks: {
                callback: (value: any) => {
                  return value;
                },
                autoSkip: true,
                maxTicksLimit: 10,
              },
            },
          }
        : {}),
      ...(doubleY
        ? {
            cashFlow: {
              type: "linear" as const,
              display: true,
              position: "right" as const,
              ticks: {
                callback: (value: any) => {
                  return currencyFormatter(portfolioCurrency).format(value);
                },
                autoSkip: true,
                maxTicksLimit: 10,
              },
            },
          }
        : {}),
    },
  };
};
