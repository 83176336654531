import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  getInstrumentsData,
  getPortfolioData,
  getServicesData,
  tableColumns,
} from "./utils";
import { ValuationData } from "src/utils/types/outputTypes";
import { useGetSecuritiesInfo } from "src/queries/useGetSecuritiesInfo";
import { useInputContext } from "src/contexts";
import { getNameFromInput } from "src/utils/functions";
import { styles } from "../styles";
import { ServiceNameCellContent } from "../ServicesTable";

const NameCellContent = ({
  isinIn,
  serviceIn,
}: {
  isinIn: string;
  serviceIn: string;
}) => {
  const { inputItems } = useInputContext();
  const { data } = useGetSecuritiesInfo({ isinIn, serviceIn });
  return (
    <span>
      {data
        ? data[0]?.name ||
          getNameFromInput({
            productId: isinIn,
            serviceId: serviceIn,
            input: inputItems,
          })
        : "--"}
    </span>
  );
};

export const PerformanceTable = ({
  data,
  filteredType,
  xirrData,
}: {
  data: any;
  xirrData: any;
  filteredType: string | number | undefined;
}) => {
  const { sortedItems } = useInputContext();
  const shownItems = filteredType ? sortedItems[filteredType] : [];
  const serviceIDs = filteredType
    ? sortedItems[filteredType].map((item) => item.serviceID)
    : [];

  const getValueFromPath = (obj: any, path: string): any => {
    if (path.includes("+")) {
      const paths = path.split("+").map((p) => p.trim());
      return paths.reduce((sum: number, p: string) => {
        const value = getValueFromPath(obj, p);
        if (typeof value === "number") {
          return sum + value;
        }
        return sum;
      }, 0);
    }

    return path.split(".").reduce((o: any, key: string) => {
      return o && o[key] !== "undefined" ? o[key] : undefined;
    }, obj);
  };

  return (
    <Box>
      <Typography sx={styles.title}>Výkonnost</Typography>
      <Table sx={styles.table} aria-label="simple table">
        <TableHead sx={styles.tableHeaderRow}>
          <TableRow>
            {Object.keys(tableColumns).map((key) => (
              <TableCell
                key={key}
                component="th"
                scope="row"
                sx={styles.tableHeader}
              >
                {tableColumns[key as keyof typeof tableColumns]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {Object.keys(tableColumns).map((key) => {
              const instrumentData =
                getPortfolioData(data, data, xirrData) || {};
              return (
                <TableCell key={`portfolio-${key}`} component="th" scope="row">
                  {key === "name"
                    ? "Portfolio celkem"
                    : instrumentData[key as keyof typeof instrumentData]}
                </TableCell>
              );
            })}
          </TableRow>
          {data.byServices.map((service: any, index: number) => {
            return filteredType &&
              !serviceIDs.includes(service.serviceId) ? null : (
              <TableRow key={service.serviceId}>
                {Object.keys(tableColumns).map((key) => {
                  const instrumentData =
                    getServicesData(service, xirrData.byServices?.[index]) ||
                    {};
                  return (
                    <TableCell
                      key={`${service.serviceId}-${key}`}
                      component="th"
                      scope="row"
                    >
                      {key === "name" ? (
                        <ServiceNameCellContent
                          serviceIn={
                            instrumentData[key as keyof typeof instrumentData]
                          }
                        />
                      ) : (
                        instrumentData[key as keyof typeof instrumentData]
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {data.byServices.map((service: any, index: number) =>
            service.byProducts.map((product: any, productIndex: number) =>
              filteredType &&
              !shownItems.some(
                (item) =>
                  item.productID === product.productId &&
                  item.serviceID === service.serviceId
              ) ? null : (
                <TableRow key={`${service.serviceId}-${product.productId}`}>
                  {Object.keys(tableColumns).map((key) => {
                    const instrumentData =
                      getInstrumentsData(
                        product,
                        xirrData.byServices?.[index]?.byProducts?.[productIndex]
                      ) || {};
                    return (
                      <TableCell
                        key={`${service.serviceId}-${product.productId}-${key}`}
                        component="th"
                        scope="row"
                      >
                        {key === "name" ? (
                          <NameCellContent
                            isinIn={
                              instrumentData[key as keyof typeof instrumentData]
                            }
                            serviceIn={service.serviceId}
                          />
                        ) : (
                          instrumentData[key as keyof typeof instrumentData]
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )
            )
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
