import { useState } from "react";
import { Box, Stack, AppBar, Toolbar, Button } from "@mui/material";
import { AuthTokenDialog, SaveInputDialog } from "src/components/popups";
import { useAuth } from "src/contexts";
import { LoadInputDialog } from "src/components/popups/LoadInputDialog";
import { AppTitle, Logo } from "src/components/icons";

const style = {
  wrapper: {
    borderBottom: "1px solid #e5e7eb",
    boxShadow: "none",
    backgroundColor: "#fff",
  },
};

export const PageBar = () => {
  const { authToken } = useAuth();
  const [authPopupOpen, setAuthPopupOpen] = useState(false);
  const [savePopupOpen, setSavePopupOpen] = useState(false);
  const [loadPopupOpen, setLoadPopupOpen] = useState(false);

  return (
    <>
      <AppBar position="fixed" sx={style.wrapper}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Stack>
            <Logo />
            <Box sx={{ marginLeft: "28px" }}>
              <AppTitle />
            </Box>
          </Stack>
          <Stack direction="row" gap="12px">
            <Button variant="text" onClick={() => setLoadPopupOpen(true)}>
              Načíst uložené produkty
            </Button>
            <Button variant="text" onClick={() => setSavePopupOpen(true)}>
              Uložit produkty
            </Button>
            <Button variant="text" onClick={() => setAuthPopupOpen(true)}>
              Změna tokenu
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>

      <AuthTokenDialog
        open={!authToken || authPopupOpen}
        setOpen={setAuthPopupOpen}
      />

      <SaveInputDialog open={savePopupOpen} setOpen={setSavePopupOpen} />

      <LoadInputDialog open={loadPopupOpen} setOpen={setLoadPopupOpen} />
    </>
  );
};
