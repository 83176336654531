import { FormFieldProps } from "src/utils/types/inputTypes";

export const feesFields: FormFieldProps[] = [
  {
    type: "autoComplete",
    label: "Typ poplatku",
    name: "typeOfFee",
    optionsKey: "typeOfFeeOptions",
  },
  {
    type: "autoComplete",
    label: "Způsob úhrady poplatku",
    name: "feePaymentMethod",
    optionsKey: "feePaymentMethodOptions",
    placeHolder: "Vyberte",
  },
  {
    type: "textField",
    label: "Sleva na poplatku per ISIN (v %)",
    name: "feeDiscount",
    fieldType: "number",
    placeHolder: "Vyplňte",
  },
];
