/* eslint-disable no-nested-ternary */
import { useQuery } from "react-query";
import axios from "axios";
import QueryString from "qs";
import { useAuth } from "src/contexts/AuthContext";
import { useConfig } from "src/contexts/ConfigContext";

export const useGetServicesInfo = ({ serviceIn }: { serviceIn?: string }) => {
  const { authToken } = useAuth();
  const { config } = useConfig();

  const getOptionsFromFetch = (data: any) => {
    const transformedData = data.rows.map((obj: any) => ({
      value: obj.serviceInfo.service.id,
      name: obj.serviceInfo.service.name.cs,
    }));
    return transformedData;
  };

  const fetch = async () => {
    const res = await await axios.get(
      `${config.catalogUrl}${config.version}/Services`,
      {
        params: {
          serviceIn: serviceIn || null,
        },
        paramsSerializer: {
          serialize: (params) => {
            return QueryString.stringify(params, { skipNulls: true });
          },
        },
        headers: {
          correlationid: "382d3541-1c07-47d9-8426-d04152e82261",
          ofsauthorization: `Bearer ${authToken}`,
        },
      }
    );

    return getOptionsFromFetch(res.data);
  };

  return useQuery(`getServicesInfo-${serviceIn}`, fetch, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
    enabled: !!authToken && !!config.catalogUrl,
  });
};
