import {
  currencyFormatter,
  percentFormatter,
} from "src/utils/functions/helpers";
import {
  PortfolioDataType,
  Product,
  ServiceDataType,
  ValuationData,
} from "src/utils/types/outputTypes";

export const tableColumns = {
  name: "Název fondu/služby",
  m1: "m1",
  m3: "m3",
  m6: "m6",
  y1: "y1",
  ytd: "ytd",
  y3: "y3",
  y5: "y5",
  all: "all",
  y1Pa: "y1Pa",
  y3Pa: "y3Pa",
  y5Pa: "y5Pa",
  allPa: "allPa",
  allPaXIRR: "allPa (XIRR)",
};

const getPercent = (number: number | null): string => {
  if (number !== null && typeof number === "number")
    return percentFormatter().format(number || 0);
  else return "- -";
};

export const getPortfolioData = (
  portfolioData: any,
  data: any,
  xirrData: any
) => {
  if (data) {
    return {
      name: data.productId,
      m1: getPercent(portfolioData.m1),
      m3: getPercent(portfolioData.m3),
      m6: getPercent(portfolioData.m6),
      y1: getPercent(portfolioData.y1),
      ytd: getPercent(portfolioData.ytd),
      y3: getPercent(portfolioData.y3),
      y5: getPercent(portfolioData.y5),
      all: getPercent(portfolioData.all),
      y1Pa: getPercent(portfolioData.y1Pa),
      y3Pa: getPercent(portfolioData.y3Pa),
      y5Pa: getPercent(portfolioData.y5Pa),
      allPa: getPercent(portfolioData.allPa),
      allPaXIRR: getPercent(xirrData?.allPa),
    };
  }
};

export const getInstrumentsData = (data: any, xirrData: any) => {
  if (data) {
    return {
      name: data.productId,
      m1: getPercent(data.m1),
      m3: getPercent(data.m3),
      m6: getPercent(data.m6),
      y1: getPercent(data.y1),
      ytd: getPercent(data.ytd),
      y3: getPercent(data.y3),
      y5: getPercent(data.y5),
      all: getPercent(data.all),
      y1Pa: getPercent(data.y1Pa),
      y3Pa: getPercent(data.y3Pa),
      y5Pa: getPercent(data.y5Pa),
      allPa: getPercent(data.allPa),
      allPaXIRR: getPercent(xirrData?.allPa),
    };
  }
};

export const getServicesData = (data: any, xirrData: any) => {
  if (data) {
    return {
      name: data.serviceId,
      m1: getPercent(data.m1),
      m3: getPercent(data.m3),
      m6: getPercent(data.m6),
      y1: getPercent(data.y1),
      ytd: getPercent(data.ytd),
      y3: getPercent(data.y3),
      y5: getPercent(data.y5),
      all: getPercent(data.all),
      y1Pa: getPercent(data.y1Pa),
      y3Pa: getPercent(data.y3Pa),
      y5Pa: getPercent(data.y5Pa),
      allPa: getPercent(data.allPa),
      allPaXIRR: getPercent(xirrData?.allPa),
    };
  }
};
