import { SecurityFormProps } from "src/utils/types/inputTypes";

export const cashFlowDefault = {
  cashFlowCurrency: { value: "", label: "" },
  amount: null,
  from: null,
  to: null,
  frequency: { value: "", label: "" },
};

export const withdrawalsDefault = {
  amount: null,
  from: null,
  to: null,
  frequency: { value: "", label: "" },
};

export const defaultValues: SecurityFormProps = {
  type: { value: "security", label: "Cenný papír" },
  id: null,
  invBusPartnerIn: { value: "", label: "" },
  serviceIn: { value: "", label: "" },
  portfolioCurrency: { value: "", label: "" },
  modelationType: { value: "", label: "" },
  product: {
    value: "",
    label: "",
    data: { currency: "", serviceIn: "", invBusPartnerIn: "" },
  },
  currency: { value: "", label: "" },
  typeOfFee: { value: "", label: "" },
  feePaymentMethod: { value: "", label: "" },
  feeDiscount: null,
  cashFlow: [cashFlowDefault],
  withdrawals: [withdrawalsDefault],
};
