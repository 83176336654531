import {
  BuildingSavingsFormProps,
  CashAccountFormProps,
} from "src/utils/types/inputTypes";

export const cashFlowDefault = {
  cashFlowCurrency: { value: "", label: "" },
  amount: null,
  from: null,
  to: null,
  frequency: { value: "", label: "" },
};

export const withdrawalsDefault = {
  amount: null,
  from: null,
  to: null,
  frequency: { value: "", label: "" },
};

export const savingsGoalDefault = {
  currency: { value: "", label: "" },
  amount: null,
};

export const defaultValues: CashAccountFormProps = {
  type: { value: "cash-account", label: "Hotovostní účet" },
  id: null,
  invBusPartnerIn: { value: "", label: "" },
  serviceIn: { value: 0, label: "Obecná služba" },
  modelationType: { value: "", label: "" },
  product: {
    value: "",
    label: "",
    data: {
      currency: "",
      serviceIn: "",
      invBusPartnerIn: "",
    },
  },
  interest: null,
  portfolioCurrency: { value: "", label: "" },
  cashFlow: [cashFlowDefault],
};
