import { currencyFormatter } from "src/utils/functions/helpers";
import { ServiceDataType, ValuationData } from "src/utils/types/outputTypes";

export const instrumentsLabels = {
  name: "Název služby",
  portfolioCurrency: "Referenční měna portfolia",
  totalDeposits: "Vklady celkem",
  interests: "Připsané úroky celkem",
  value: "Konečná hodnota instrumentu",
};

export const getInstrumentsData = (
  portfolioData: ValuationData,
  data: ServiceDataType
) => {
  if (data?.summary) {
    return {
      name: data.serviceId,
      portfolioCurrency: portfolioData.portfolio.currency,
      totalDeposits: currencyFormatter(
        portfolioData?.portfolio?.currency
      ).format(
        data.summary.clientDeposits +
          data.summary.employerContributions +
          data.summary.governmentContributions +
          data.summary.interests
      ),

      interests: currencyFormatter(portfolioData?.portfolio?.currency).format(
        data.summary.interests
      ),
      value: currencyFormatter(portfolioData?.portfolio?.currency).format(
        data.summary.value
      ),
    };
  }
};
