import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { CashFlowData } from "../../types";
import { CashFlow } from "../../types/inputTypes";

export const convertCashFlow = ({
  cashFlow,
  instrumentCurrency,
  type,
}: {
  cashFlow: CashFlow;
  instrumentCurrency: string;
  type: number;
}): CashFlowData => {
  const getFundingType = (): ReturnType<
    () => { type: "initial" | "regular" | "one-off"; timeData: object }
  > => {
    if (cashFlow?.from && cashFlow?.frequency?.value) {
      return {
        type: "regular",
        timeData: {
          from: cashFlow?.from ? dayjs(cashFlow.from).format("YYYY-MM-DD") : "",
          ...(cashFlow?.to
            ? { to: dayjs(cashFlow.to).format("YYYY-MM-DD") }
            : {}),
          ...(cashFlow?.frequency?.value !== 0 && cashFlow?.frequency?.value
            ? { frequency: Number(cashFlow?.frequency?.value) }
            : {}),
        },
      };
    } else if (cashFlow?.from && !cashFlow?.frequency?.value) {
      return {
        type: "one-off",
        timeData: { date: dayjs(cashFlow?.from).format("YYYY-MM-DD") },
      };
    } else
      return {
        type: "initial",
        timeData: {},
      };
  };

  const fundingType = getFundingType();
  const timeData = fundingType?.timeData;

  if (!cashFlow.amount) {
    return null;
  } else
    return {
      fundingType: fundingType?.type,
      cashFlowType: type,
      amount: cashFlow?.amount || 0,
      currency: cashFlow?.cashFlowCurrency?.value
        ? String(cashFlow?.cashFlowCurrency?.value || "")
        : instrumentCurrency,
      ...timeData,
    };
};

export const chooseGranularity = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  dayjs.extend(duration);

  const start = dayjs(startDate);
  const currentDate = new Date();
  const end = dayjs(endDate);

  const historyDiff = Math.abs(start.diff(currentDate, "day"));
  const futureDiff = Math.abs(end.diff(currentDate, "day"));
  const differenceInDays = historyDiff > futureDiff ? futureDiff : historyDiff;

  const interval = differenceInDays / 25;

  if (interval <= 1) {
    return 1; // 1 den
  } else if (interval <= 3) {
    return 2; // 3 dny
  } else if (interval <= 5) {
    return 3; // 5 dní
  } else if (interval <= 7) {
    return 4; // 1 týden
  } else if (interval <= 14) {
    return 5; // 2 týdny
  } else if (interval <= 30) {
    return 6; // 1 měsíc
  } else if (interval <= 60) {
    return 7; // 3 měsíce
  } else if (interval <= 120) {
    return 8; // 6 měsíců
  } else {
    return 9; // 1 rok
  }
};
