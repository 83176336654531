import * as React from "react";
import {
  Box,
  IconButton,
  Menu,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export type FilterButtonProps = {
  id: "portfolioData" | "serviceData" | "productsData";
  label: string;
}[];

export const FilterButton = ({
  currentDataset,
  setCurrentDataset,
}: {
  currentDataset: ("portfolioData" | "serviceData" | "productsData")[];
  setCurrentDataset: (
    currentDataset: ("portfolioData" | "serviceData" | "productsData")[]
  ) => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const options: FilterButtonProps = [
    { id: "portfolioData", label: "Portfolio" },
    { id: "serviceData", label: "Služby" },
    { id: "productsData", label: "Produkty" },
  ];

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        aria-label="show filters"
        sx={styles.button}
      >
        <FilterAltOutlinedIcon color="primary" /> <ExpandMoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={styles.menu}
      >
        <FormGroup>
          {options.map(({ id, label }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={currentDataset.includes(id)}
                  onChange={() => {
                    if (currentDataset.includes(id)) {
                      setCurrentDataset(
                        currentDataset.filter((dataset) => dataset !== id)
                      );
                    } else setCurrentDataset([...currentDataset, id]);
                  }}
                />
              }
              key={id}
              label={label}
            />
          ))}
        </FormGroup>
      </Menu>
    </Box>
  );
};

const styles = {
  button: {
    borderRadius: "50px",
    border: "1px solid #979797",
  },
  menu: {
    ".MuiMenu-paper": {
      padding: "12px 20px 12px 20px",
      borderRadius: "10px",
      border: "1px",
      gap: "10px",
    },
  },
};
