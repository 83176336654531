import { useContext } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import { useAuth } from "src/contexts/AuthContext";
import { useConfig } from "src/contexts/ConfigContext";

type UseSubmitProps = {
  onSuccess: (data: any) => void;
  onError?: (data: any) => void;
};

export const usePostFuture = ({ onSuccess, onError }: UseSubmitProps) => {
  const { authToken } = useAuth();
  const { config } = useConfig();

  return useMutation(
    (data: object) => {
      return axios.post(
        `${config.portfolioLabUrl}${config.version}/Forecast`,
        data,
        {
          headers: {
            accept: "application/json",
            contentType: "application/json",
            correlationid: "382d3541-1c07-47d9-8426-d04152e82261",
            ofsauthorization: `Bearer ${authToken}`,
            userAgent: "vscode-restclient",
          },
        }
      );
    },
    { onSuccess, onError }
  );
};
