/* eslint-disable no-nested-ternary */
import { useQuery } from "react-query";
import axios from "axios";

import QueryString from "qs";
import { useAuth } from "src/contexts/AuthContext";
import { useConfig } from "src/contexts/ConfigContext";

export const useGetServices = ({
  invBusPartnerIn,
}: {
  invBusPartnerIn?: string | number | boolean;
}) => {
  const { authToken } = useAuth();
  const { config } = useConfig();

  const getOptionsFromFetch = (data: any) => {
    const transformedData = data.rows.map((obj: any) => ({
      value: obj.serviceIdentification.service.id,
      label: obj.serviceIdentification.service.name.cs,
    }));
    transformedData.push({ value: "0", label: "Obecná služba" });
    return transformedData;
  };

  const fetch = async () => {
    const res = await axios.get(
      `${config.catalogUrl}${config.version}/Services`,
      {
        params: {
          inUse: true,
          pageSize: 500,
          invBusPartnerIn: invBusPartnerIn || null,
        },
        paramsSerializer: {
          serialize: (params) => {
            return QueryString.stringify(params, { skipNulls: true });
          },
        },

        headers: {
          accept: "application/json",
          contentType: "application/json",
          correlationid: "382d3541-1c07-47d9-8426-d04152e82261",
          ofsauthorization: `Bearer ${authToken}`,
          userAgent: "vscode-restclient",
        },
      }
    );
    const transformedData = getOptionsFromFetch(res.data);
    return transformedData;
  };

  return useQuery(`getServices-${invBusPartnerIn}`, fetch, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
    enabled: !!authToken && !!config.catalogUrl,
  });
};
