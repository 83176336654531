import * as React from "react";
import { TextField, TextFieldProps } from "@mui/material";

interface Props {
  label: string;
  name: string;
  disabled?: boolean;
  value: any;
  placeHolder?: string;
  onChangeField: (name: string, newValue: string | null) => void;
}

export const ControlledTextField: React.FC<TextFieldProps & Props> = ({
  label,
  name,
  disabled,
  value,
  placeHolder,
  onChangeField,
  ...props
}) => {
  return (
    <TextField
      label={label}
      disabled={disabled}
      value={value ? String(value) : ""}
      onChange={(e) => onChangeField(name, e.target.value)}
      placeholder={placeHolder || "Zadejte"}
      InputLabelProps={{ shrink: true }}
      variant="standard"
      type={props.type}
      required={props.required}
    />
  );
};
