import * as React from "react";
import { OptionType } from "../../../utils/types/inputTypes";
import { Autocomplete, TextField } from "@mui/material";
import { useGetCurrency } from "src/queries";
import { useInputContext } from "src/contexts/InputContext";
import { findOptionForValue } from "src/utils/functions/helpers";

const PortfolioCurrencyField = ({ sx }: { sx?: any }) => {
  const title = "Referenční měna portfolia";
  const { data: currencyOptions } = useGetCurrency();
  const { portfolioData, setPortfolioData } = useInputContext();

  return (
    <Autocomplete
      options={currencyOptions || []}
      getOptionLabel={(option: OptionType) => option?.label || ""}
      placeholder={"Vyberte"}
      value={
        findOptionForValue({
          value: portfolioData.currency,
          options: currencyOptions,
        }) || ("" as unknown as OptionType)
      }
      onChange={(e, newValue) =>
        setPortfolioData({
          ...portfolioData,
          currency: String(newValue?.value || ""),
        })
      }
      sx={{ width: "100%", ...(sx ? sx : {}) }}
      isOptionEqualToValue={(
        option: OptionType,
        value: OptionType | "" | undefined
      ) =>
        value === undefined ||
        value === "" ||
        value.value === "" ||
        option.value === value?.value ||
        option.value === (value as unknown as number)
      }
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={title}
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        );
      }}
    />
  );
};

export default PortfolioCurrencyField;
