import { DPSFormProps } from "src/utils/types/inputTypes";
import { convertCashFlow } from "./promptConversionHelpers";

export const getDpsPrompt = ({ product }: { product: DPSFormProps }) => {
  const transformedClientCashFlow = product.clientCashFlow?.map((obj) =>
    convertCashFlow({
      cashFlow: obj,
      type: 1,
      instrumentCurrency: product.product.data.currency,
    })
  );
  const transformedEmployerCashFlow = product.employerCashFlow?.map((obj) =>
    convertCashFlow({
      cashFlow: obj,
      type: 6,
      instrumentCurrency: product.product.data.currency,
    })
  );

  const transformedWithdrawals = product.withdrawals?.map((obj) =>
    convertCashFlow({
      cashFlow: obj,
      type: 3,
      instrumentCurrency: product.product.data.currency,
    })
  );

  const cashFlows = [
    ...(transformedClientCashFlow
      ? transformedClientCashFlow.filter((value) => value !== null)
      : []),
    ...(transformedEmployerCashFlow
      ? transformedEmployerCashFlow.filter((value) => value !== null)
      : []),
    ...(transformedWithdrawals
      ? transformedWithdrawals.filter((value) => value !== null)
      : []),
  ];

  const transformedProduct = {
    type: product.type?.value,
    id: product.product.value,
    cashFlow: cashFlows,
  };

  return transformedProduct;
};
